import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@mui/material";
import { AddProjectForm } from "../components/form-add-project";
import useGetProjects from "../hooks/useGetProjects";
import { v4 } from "uuid";
import { loadStats, sendDealNotification } from "../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { DocumentTitle } from "../../../components/DocumentTitle";

const status = [
  { value: "draft", label: "Setup & Launch" },
  { value: "evaluation", label: "Collecting Viewpoints" },
  { value: "analysis", label: "Analysis & Results" },
  { value: "firm_investment", label: "Firm Investment" },
  { value: "spv", label: "BrightLoop Expert SPV" },
  { value: "completed", label: "Completed" }
];

type TAdminProjects = {
  filteredProjects: any[],
  highlightedProjects: any | null
};

const initState: TAdminProjects = {
  filteredProjects: [],
  highlightedProjects: null
}

export const AdminProjects = () => {
  const [ state, setState ] = useState(initState);

  const [projects] = useGetProjects();

  const { getAccessTokenSilently } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if(!projects) return;

    setState({
      ...state,
      filteredProjects: projects
    });
  }, [projects]);

  const handleAddProjectClick = async ( event: any ) => {
    event.target.parentElement.parentElement.classList.add("show-form");
    event.preventDefault();
  };

  const handleCallback = async ( project: any ) => {
    setState({
      ...state,
      highlightedProjects: project.id,
    });
  
    document.getElementsByClassName("add-object-wrapper")[0].classList.remove("show-form");
  };

  const handleSearch = (event: any) => {
    const queryString = event.target.value;
    const filteredProjects = projects.filter((project: any) => {
      return project.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
    });

    setState({
      ...state,
      filteredProjects: filteredProjects
    });
  };

  const handleReloadStats = async (event: any) => {
    event.preventDefault();

    const origText = event.target.innerText;

    event.target.innerText = "Reloading...";
    const accessToken = await getAccessTokenSilently();
    const projectId = event.target.parentElement.parentElement.children[0].innerText;
    const updatedProject = await loadStats(projectId, accessToken);
    event.target.innerText = origText;
  }


  return (
    <>
    <DocumentTitle pageTitle="Admin - Projects" />
      <div className="flex-box-wrapper">
        <div className="add-object-wrapper">
          <div className="add-object-toggle hidden">
          <div className="add-object-message"></div>
            <button
              onClick={handleAddProjectClick}
            >
              Add Project
            </button>
          </div>
          <div className="add-object-form">
            <AddProjectForm parentCallback={handleCallback} />
          </div>
        </div>
        <div className="admin-list-filter">
          Search: <input type="text" id="search-box" onChange={handleSearch} />
        </div>
      </div>
      <TableContainer>
        <Table stickyHeader className="admin-table"> 
          <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Tag</TableCell>
            <TableCell>Organization</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

      {state.filteredProjects.map((project) => {
        const url = `/admin/project/${project.id}`;
        return (
          <TableRow key={v4()} id={project.id} className={state.highlightedProjects && project.id === state.highlightedProjects ? "highlighted" : ""}>
            <TableCell>{project.id}</TableCell>
            <TableCell><Link to={url}>{project.name ? project.name : "no name"}</Link></TableCell>
            <TableCell>{project.tag}</TableCell>
            <TableCell>{project.organization.name}</TableCell>
            <TableCell>{status.find(v => v.value === project.status)?.label}</TableCell>
            <TableCell>
              <div className="link" onClick={handleReloadStats}>Reload Stats</div>
              <div><Link to={`/admin/project/${project.id}/surveyLinks`}>Survey Links</Link></div>
              <div><Link to={`/admin/project/${project.id}/viewpoints`}>Manage Viewpoints</Link></div>
              <div><Link to={`/admin/project/${project.id}/deal`}>Deal Info</Link></div>
              </TableCell>
          </TableRow>
        );
          })}
        </TableBody>
      </Table>
      </TableContainer>
    </>
  );
};

export default AdminProjects;