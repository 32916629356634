import StyledDropzone from "../../../../components/AssetUpload";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, Stack, styled, Switch, Tab, Tabs, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import type { DialogProps } from "@mui/material";
import { uploadAsset, uploadLogo } from "../../../../core/services/gutcheck-api.service";
import ProjectAsset from "../../../projects/types/ProjectAsset";
import ClientProvider from "../../../../modules/ClientProvider";
import { v4 } from "uuid";
import CollaborativeEditor from "../../../../components/CollaborativeEditor";
import { ModalBox } from "../../../../components/ModalBox";
import { nanoid } from "nanoid";

const FormatButton = styled('button')({
    backgroundColor: "#f8f9fa",
    border: "none",
    color: "black",
    padding: "10px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    fontSize: "1.2rem",
});

const EditorBox = styled(Box)({
    aspectRation: '16/9',
    width: '100%',
    "& .ProseMirror": {
        height: "calc(100vh - 340px)",
        maxHeight: "600px",
        overflow: "auto",
        width: '100%',
        border: '1px solid #c9c9c9',
        backgroundColor: "#ffffff",
        padding: '1rem',
        borderRadius: "4px"
    }

});

const UploadBox = styled(Box)(({ theme }) => ({
    width: "100%",
    aspectRatio: '16/9',
    overflowY: "auto",
    "& img": {
        maxWidth: "100%",
        height: "auto",
    }
}));

const convertPreviewLink = (link: string | null) => {
    if (!link) return null;
    if (link.includes("youtube.com") || link.includes("vimeo.com")) {
        return `https://www.youtube.com/embed/${link.split("v=")[1]}`;
    }
    return link;
}


export const DealAssetForm = ({ project, dealAsset, dealAssets, reloadDealAssets, editMode, setEditMode, dealAssetType }: any) => {
    const { getAccessTokenSilently } = useAuth0();

    const initialProjectAsset: any = {
        id: dealAsset?.id ?? null,
        uuid: dealAsset?.uuid ?? null,
        type: dealAsset?.type && dealAsset?.type !== "" ? dealAsset.type : dealAssetType,
        format: dealAsset?.format ?? (dealAssetType === "Product Description" ? "text" : "file"),
        title: dealAsset?.title ?? "",
        filename: dealAsset?.filename ?? "",
        description: dealAsset?.description ?? "",
        asset_url: dealAsset?.asset_url ?? "",
        asset_data: dealAsset?.asset_data ?? "",
        asset_file_id: dealAsset?.asset_file_id ?? "",
        thumbnail_url: dealAsset?.thumbnail_url ?? "",
        is_deleted: dealAsset?.is_deleted ?? false,
    }

    const [projectAssetForm, setProjectAssetForm] = useState(initialProjectAsset);

    const initQueuedFiles: File[] = [];
    const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);
    const [assetText, setAssetText] = useState(projectAssetForm?.asset_data);
    const [previewLink, setPreviewLink] = useState(convertPreviewLink(dealAsset?.asset_url));
    const [editTitle, setEditTitle] = useState(dealAsset?.title && dealAsset?.title !== "" ? false : true);
    const [submitting, setSubmitting] = useState(false);
    const [id, setId] = useState(nanoid(6));

    const filename = dealAsset?.filename ?? `${project.organization.tag}/${project.tag}/asset-${id}`;

    useEffect(() => {
        if (queuedFiles.length > 0 && projectAssetForm.format === "file" && projectAssetForm.title === "") {
            setProjectAssetForm({
                ...projectAssetForm,
                title: queuedFiles[0].name
            });
        }
    }, [queuedFiles]);


    const handleSubmit = async () => {
        setSubmitting(true);
        const accessToken = await getAccessTokenSilently();

        let asset_file_id = projectAssetForm.asset_file_id;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadAsset(queuedFiles[0], project.organization.tag, project.tag, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            asset_file_id = data.response.location;
        }

        const newProjectAsset = projectAssetForm;
        newProjectAsset.asset_file_id = asset_file_id;

        if (projectAssetForm.format === "text") {
            newProjectAsset.asset_data = assetText;
            newProjectAsset.filename = filename;
        }


        if (dealAsset?.id) {
            await ClientProvider.provideProjectClient().updateAsset(project.id, dealAsset.id, newProjectAsset, accessToken);
        } else {
            await ClientProvider.provideProjectClient().createAsset(project.id, newProjectAsset, accessToken);

        }
        setProjectAssetForm(initialProjectAsset);
        await reloadDealAssets();
        setEditMode(false);
    }

    const handleChange = (event: any) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        setProjectAssetForm({ ...projectAssetForm, [event.target.name]: value });
    }

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setEditMode(false);
    }

    const handleCancel = (event: any) => {
        event.preventDefault();
        setEditMode(false);
    }

    const handleFormatChange = (event: any) => {
        setProjectAssetForm({
            ...projectAssetForm,
            format: event.target.value
        });
    }

    const handleLinkPreview = () => {
        setPreviewLink(convertPreviewLink(projectAssetForm.asset_url));
    }

    const handleDelete = async () => {
        const accessToken = await getAccessTokenSilently();
        await ClientProvider.provideProjectClient().deleteAsset(project.id, projectAssetForm.id, accessToken);
        await reloadDealAssets();
        setEditMode(false);
    }

    const handleEditName = () => {
        setEditTitle(true);
    }

    return (
        <Modal
            open={editMode} onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox sx={{ width: "900px" }}>

                    <div className="">
                        <h5>{dealAsset?.id ? "Edit Your" : "Add a"} {dealAssetType === "Product Description" ? "Product Tear Sheet" : dealAssetType}</h5>
                    </div>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: "20px",
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            width: "60%",
                        }}>

                            {editTitle ? <TextField name="title" value={projectAssetForm.title} onChange={handleChange} label="Name" size="small" /> : <h5>Name: {projectAssetForm.title} (<span className="link" onClick={handleEditName}>change</span>)</h5>}

                            {!projectAssetForm.id &&
                                <ToggleButtonGroup exclusive aria-label="format" value={projectAssetForm.format} onChange={handleFormatChange}>
                                    {dealAssetType === "Product Description" && <ToggleButton value="text">Enter your content</ToggleButton>}
                                    <ToggleButton value="file">Upload a file</ToggleButton>
                                    {dealAssetType !== "Product Description" && <ToggleButton value="link">Provide a link</ToggleButton>}
                                </ToggleButtonGroup>
                            }

                            {projectAssetForm.format === "text" &&
                                <EditorBox>
                                    <CollaborativeEditor documentName={filename} form={assetText} setForm={setAssetText} />
                                </EditorBox>
                            }

                            {projectAssetForm.format === "file" &&
                                <UploadBox>
                                    <StyledDropzone fileTypes={{ 'image/*': [], 'video/*': [], 'application/pdf': [] }} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} initialFile={projectAssetForm.asset_file_id ?? ""} />
                                </UploadBox>
                            }

                            {projectAssetForm.format === "link" &&
                                <>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "10px",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}>
                                        <TextField name="asset_url" value={projectAssetForm.asset_url} onChange={handleChange} label={`URL of ${projectAssetForm.type}`} size="small" /> <span className="link" onClick={handleLinkPreview}>Preview</span>
                                    </Box>
                                    {previewLink && previewLink !== "" &&
                                        <iframe src={previewLink} width="100%" height="400px"></iframe>
                                    }
                                </>
                            }

                            {submitting && <p>Submitting...</p>}
                            {!submitting &&
                                <Stack direction="row" spacing={2} alignContent="center" justifyContent="space-between" alignItems="center">
                                    <Box>
                                    { dealAsset?.id && <Typography className="link" sx={{ color: "red" }} onClick={handleDelete}>Delete</Typography> }
                                    </Box>
                                    <Stack direction="row" spacing={2} alignContent="center" justifyContent="flex-end">
                                        <button type="button" className="btn btn-secondary" onClick={handleCancel}>Cancel</button>
                                        <button type="button" onClick={handleSubmit}>Submit</button>
                                    </Stack>
                                </Stack>
                            }
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            flex: 1,
                            fontSize: "0.9rem",
                        }}>
                            {dealAssetType === "Product Description" &&
                                <div className="tips-content">
                                <p><span className="bold">Notes</span></p>
                                <p>This will be blinded and may be presented as supplementary material behind the product description.</p>
                            </div>
                            }
                            {projectAssetForm.type === "Sales Deck" &&
                                <div className="tips-content">
                                    <p><span className="bold">Notes</span></p>
                                    <p>This will be blinded and may be presented as supplementary material behind the product description.</p>
                                </div>
                            }

                            {projectAssetForm.type === "Product Demo" &&
                                <div className="tips-content">
                                    <p><span className="bold">Notes</span></p>
                                    <p>This may be presented as supplementary material behind the product description.</p>
                                </div>
                            }

                        </Box>
                    </Box>

            </ModalBox>
        </Modal>
    );
}