import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { getAllDataForSelf } from '../../../core/services/gutcheck-api.service';
import Membership from '../types/Membership';

export interface iUserContext {
    userContext: {
        auth0User: any;
        dbUser: any;
        organizationMemberships: any;
        projectMemberships: any;
        fetched: boolean;
    };
    setUserContext: React.Dispatch<React.SetStateAction<any>>;
    loadUserContext: () => void;
}

export const UserContext = React.createContext<iUserContext>({
    userContext: {
        auth0User: {},
        dbUser: {},
        organizationMemberships: [],
        projectMemberships: [],
        fetched: false
    },
    setUserContext: () => { },
    loadUserContext: () => { }
});

const loadUserContext = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { userContext, setUserContext } = useContext(UserContext);

    useEffect(() => {
        const getUserContext = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getAllDataForSelf(accessToken);
                setUserContext({
                    ...userContext,
                    dbUser: data.user,
                    projectMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.project }) || [],
                    organizationMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.organization }) || [],
                    fetched: true
                })
            } catch (error) {
                console.error(error);
            }
        }
        getUserContext();
    }, []);

}

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const [userContext, setUserContext] = useState<any>({
        auth0User: {},
        dbUser: {},
        organizationMemberships: [],
        projectMemberships: [],
        fetched: false
    });

    return (
        <UserContext.Provider value={{ userContext, setUserContext, loadUserContext }}>
            {children}
        </UserContext.Provider>
    );
}




