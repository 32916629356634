import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetRespondentHtml from "../../../../hooks/useGetRespondentHtml";
import { Box, Fade, Modal, styled, TableCell, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { DealProfileImage, DealCompany } from "../../../deal";
import { ViewpointForm } from "./ViewpointForm";
import { useAuth0 } from "@auth0/auth0-react";
import { approveViewpoint, rejectViewpoint, updateViewpoint } from "../../services/gutcheck-admin-api.service";
import Loading from "../../../../components/Loading";


const ExecutiveViewpoint = ({ project, viewpoint }: any) => {
    const [respondentHtml, fetched] = useGetRespondentHtml(viewpoint.uuid, project);

    return (
        <Box sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflowY: "scroll",
            "& #responseMeta": {
                display: "none"
            },
            "& #navTabs": {
                borderBottom: "1px solid #c9c9c9",
                "& .navTab.selected": {
                    backgroundColor: "#f8f9fa",
                    border: "1px solid #c9c9c9",
                    borderBottom: "none",
                },
            },
            "& #contentContainer": {
                backgroundColor: "#ffffff",
                "& .answerSummary": {
                    padding: 0
                }
            }
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "start",
                    marginBottom: "40px",
                    gap: "20px",
                    padding: "20px",
                }}
            >
                <DealProfileImage viewpoint={viewpoint} size="large" />

                <Box className="viewpoint-profile-details"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "left",
                        width: "40%"

                    }}>
                    <Typography sx={{ fontSize: "1.2rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
                    <Typography sx={{
                        fontSize: "1.2rem", textAlign: "left",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical"
                    }}>{viewpoint.title}</Typography>
                    <DealCompany viewpoint={viewpoint} style="left" />

                </Box>
            </Box>
            {fetched && respondentHtml && typeof respondentHtml === 'object' ?
                <div dangerouslySetInnerHTML={respondentHtml} />
                :
                <Loading />
            }
        </Box>
    );
}

export const AdminViewpoint = ({ project, viewpoint, reloadProjectData }: any) => {

    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editViewpoint, setEditViewpoint] = useState(null);

    const { getAccessTokenSilently } = useAuth0();

    const handleEdit = (e: any, viewpoint: any) => {
        e.preventDefault();
        e.stopPropagation();
        setEditViewpoint(viewpoint);
        setEditMode(true);
    }

    const handleApprove = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const accessToken = await getAccessTokenSilently();
        const updatedViewpoint = { ...viewpoint, is_reviewed: true, is_approved: true };
        const { data, error } = await approveViewpoint(project.id, viewpoint.id, accessToken);

        if (data) {
            reloadProjectData();
        }
    }

    const handleReject = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const accessToken = await getAccessTokenSilently();
        const updatedViewpoint = { ...viewpoint, is_reviewed: true, is_approved: false };
        const { data, error } = await rejectViewpoint(project.id, viewpoint.id, accessToken);

        if (data) {
            reloadProjectData();
        }
    }


    return (
        <>
            <TableRow key={viewpoint?.uuid} onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
                <TableCell>
                    <Typography sx={{ fontSize: "1.2rem", color: "#c9c9c9" }}>
                        {viewpoint.has_video ?
                            <FontAwesomeIcon icon="film" /> :
                            viewpoint.has_audio ? <FontAwesomeIcon icon="volume-high" />
                                : <FontAwesomeIcon icon="keyboard" />
                        }
                    </Typography>
                </TableCell>
                <TableCell>
                    {viewpoint.alchemer_id}
                </TableCell>
                <TableCell>
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", gap: "1rem" }}>
                        <DealProfileImage viewpoint={viewpoint} size="small" />
                        <Box>
                            <Typography sx={{ fontSize: "0.8rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
                            <Typography sx={{
                                fontSize: "0.8rem", textAlign: "left", overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical"
                            }}>{viewpoint.title}</Typography>
                        </Box>
                    </Box>
                </TableCell>
                <TableCell>
                    <DealCompany viewpoint={viewpoint} />
                </TableCell>
                <TableCell>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        gap: "0.5rem"
                    }}>
                        <Box>
                            {viewpoint.is_published ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />}
                        </Box>
                        <Box>
                            {!viewpoint.hidden_from_guest ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />}
                        </Box>
                        <Box>
                            {viewpoint.included_in_analysis ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="times" />}
                        </Box>
                    </Box>
                </TableCell>
                {!viewpoint.is_reviewed &&
                    <TableCell>
                        <span className="link" onClick={(e) => handleReject(e)}>Reject</span>
                    </TableCell>
                }
                {!viewpoint.is_reviewed &&
                    <TableCell>
                        <span className="link" onClick={(e) => handleApprove(e)}>Approve</span>
                    </TableCell>
                }
                {viewpoint.is_reviewed &&
                    <TableCell>
                        <Typography sx={{ fontSize: "0.8rem", color: "#999999" }}>
                            {viewpoint.highlight_quote}
                        </Typography>
                    </TableCell>}


                <TableCell className="admin-action link" onClick={(e) => handleEdit(e, viewpoint)}><FontAwesomeIcon icon="edit" /></TableCell>
            </TableRow>

            <Modal open={open} onClose={() => setOpen(false)}>
                <ExecutiveViewpoint project={project} viewpoint={viewpoint} />
            </Modal>

            {editMode &&
                <ViewpointForm project={project} viewpoint={editViewpoint} reloadProjectData={reloadProjectData} editMode={editMode} setEditMode={setEditMode} />
            }

        </>
    );
}
