import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { generateLeadsFromLinkedin } from "../services/gutcheck-admin-api.service";
import { TextField, Stack, Button, Box } from "@mui/material";

export const AdminProjectLeads = () => {
    const { project } = useOutletContext<any>();
    const [ linkedInLeads, setLinkedInLeads ] = useState<string>("");
    const [ isGeneratingLeads, setIsGeneratingLeads ] = useState<boolean>(false);
    const [ leadsGenerated, setLeadsGenerated ] = useState<string>("");
    const [ error, setError ] = useState<string>("");

    const { getAccessTokenSilently } = useAuth0();

    const handleGenerateLeads = async () => {
        const accessToken = await getAccessTokenSilently();
        setIsGeneratingLeads(true);
        const { data, error } = await generateLeadsFromLinkedin(project.id, linkedInLeads, accessToken);
        if (error) {
            setError(error.message);
        } else {
            setLeadsGenerated(data?.leads ?? "");
        }
        setIsGeneratingLeads(false);
    }
    return (
        <div>
            <h1>Generate Leads</h1>
            <p>Enter the LinkedIn URLs you want to generate leads for (1 per line).</p>
            <Stack spacing={2}>
                <TextField rows={10} multiline fullWidth value={linkedInLeads} onChange={(e) => setLinkedInLeads(e.target.value)} />
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, alignItems: "center", width: "100%" }}>
                    {error && <p style={{ color: "red" }}>{error}</p>}
                    <Button variant="contained" onClick={handleGenerateLeads} disabled={isGeneratingLeads}>{isGeneratingLeads ? "Generating..." : "Generate Leads"}</Button>
                </Box>
            </Stack>
        </div>

        

    )
}