import { Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "../../../components/AuthenticationGuard";
import { AdminUsers } from "./admin-users";
import { AdminUserEdit } from "./admin-user-edit";
import { AdminIndex } from "./admin-index";
import { Admin } from "./Admin";
import AdminOrganizations from "../organizations/admin-organizations";
import AdminOrganizationEdit from "../organizations/admin-organizations-edit";
import AdminProjects from "../projects/admin-projects";
import AdminProjectEdit from "../projects/admin-project-edit";
import AdminProjectAi from "../projects/admin-project-ai";
import { AdminSurveyLinks } from "../projects/admin-survey-links";
import { AdminSurveyLinkCreate } from "../projects/admin-survey-link-create";
import { AdminSurveyLinkEdit } from "../projects/admin-survey-link-edit";
import { AdminProject } from "../projects/admin-project";
import { AdminViewpoints } from "../projects/admin-viewpoints";
import { AdminProjectDealEdit } from "../projects/admin-project-deal-edit";
import { AdminProjectView } from "../projects/admin-project-view";
import { AdminProjectNotifications } from "../projects/admin-project-notifications";
import { AdminDealUpdates } from "../projects/admin-deal-updates";
import { AdminInvestmentMaterials } from "../projects/admin-investment-materials";
import { AdminSurveyResponses } from "../projects/admin-survey-responses";
import { AdminOrganization } from "../organizations/admin-organization";
import { AdminOrganizationMembers } from "../organizations/admin-organization-members";
import { AdminOrganizationProjects } from "../organizations/admin-organization-projects";
import { AdminProjectLeads } from "../projects/admin-project-leads";

export const AdminRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<Admin/>}>
              <Route index element={<AdminIndex/>}/>
              <Route path="users" element={<AdminUsers />} />
              <Route path="user/:userId/edit" element={<AuthenticationGuard component={AdminUserEdit} role="admin" />} />

              <Route path="organizations" element={<AuthenticationGuard component={AdminOrganizations} role="admin" />} />
              <Route path="organization/:organizationId" element={<AdminOrganization />}>
                <Route index element={<AdminOrganizationMembers />} />
                <Route path="edit" element={<AdminOrganizationEdit/>} />
                <Route path="projects" element={<AdminOrganizationProjects />} />
              </Route>
              
              <Route path="projects" element={<AdminProjects />} />
              <Route path="project/:projectId" element={<AdminProject />}>
                <Route index element={<AdminProjectView />} />
                <Route path="edit" element={<AdminProjectEdit />} />
                <Route path="ai" element={<AdminProjectAi />} />
                <Route path="surveyLinks" element={<AdminSurveyLinks />} />
                <Route path="surveyLink/new" element={<AdminSurveyLinkCreate />} />
                <Route path="surveyLink/:surveyLinkId" element={<AdminSurveyLinkEdit />} />
                <Route path="surveyResponses" element={<AdminSurveyResponses />} />
                <Route path="viewpoints" element={<AdminViewpoints />} />
                <Route path="deal" element={<AdminProjectDealEdit />} />
                <Route path="notifications" element={<AdminProjectNotifications />} />
                <Route path="dealUpdates" element={<AdminDealUpdates />} />
                <Route path="investmentMaterials" element={<AdminInvestmentMaterials />} />
                <Route path="leads" element={<AdminProjectLeads />} />
              </Route>
            </Route>
        </Routes>
    );
}