import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetRespondentHtml from "../../../hooks/useGetRespondentHtml";
import { Box, BoxProps, Fade, Modal, styled, TableCell, TableRow, TableRowProps, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DealCompany } from "./DealCompany";
import { DealProfileImage } from "./DealProfileImage";
import useAnalytics from "../../../hooks/useAnalytics";
import { useUserContext } from "../../users";
import { TapAndPlay } from "@mui/icons-material";
import { FormatQuote } from "@mui/icons-material";
import Loading from "../../../components/Loading";

type TExecutive = {
    project: any,
    viewpoint: any,
    style: string,
    user?: any,
    initialOpen?: boolean
}

const profileStyle = {
    display: "flex",
    flexDirection: "row"
};

const bubbleGrayStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
    backgroundColor: "#c6c6c6",
    borderRadius: "20px",
    height: "35px",
    width: "35px"
};

const bubbleGreenStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.5rem",
    backgroundColor: "#28a745",
    borderRadius: "20px",
    height: "35px",
    width: "35px"
};

const demandGrayStyle = {
    backgroundColor: "#c6c6c6",
    color: "#ffffff",
    padding: "0.5rem 1rem",
    borderRadius: "20px",
    height: "35px",
    textAlign: "center",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "75px"
}

const demandGreenStyle = {
    backgroundColor: "#28a745",
    color: "#ffffff",
    padding: "0.5rem 1rem",
    borderRadius: "20px",
    height: "35px",
    textAlign: "center",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "75px"
}


const demandOptions = [
    {
        value: "Yes, definitely",
        label: "Very High",
        class: "dark-green"
    },
    {
        value: "Yes, probably",
        label: "High",
        class: "light-green"
    },
    {
        value: "Maybe",
        label: "Medium",
        class: "gray"
    },
    {
        value: "No, probably not",
        label: "Low",
        class: "gray"
    },
    {
        value: "No, definitely not",
        label: "Very Low",
        class: "gray"
    }
]

const ViewpointSummary = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    padding: 0,
    gap: "0",
    width: "100%",
    "& .summary-header": {
        fontWeight: "500",
        fontSize: "0.9rem",
        display: "none"
    },
    "& .summary-pills": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
        alignItems: "center",
        gap: "5px",
        width: "100%",
        flexWrap: "nowrap",
        "& .summary-pill": {
            padding: "0.4rem 0.75rem",
            borderRadius: "18px",
            backgroundColor: "#f8f9fa",
            border: "1px dashed #c9c9c9",
            color: "#c9c9c9",
            fontSize: "0.75rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            "&.highlighted": {
                backgroundColor: "#c9c9c9",
                border: "1px solid #c9c9c9",
                color: "#000000",
            }
        },
    },
}));

const SummaryContainer = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    width: "250px"
}));

const DemandSummary = styled(Box)<BoxProps>(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    padding: 0,
    gap: "0",
    width: "100%",
    "& .summary-pill": {
        borderRadius: "18px",
        backgroundColor: "#c9c9c9",
        border: "1px solid #c9c9c9",
        color: "#000000",
        fontSize: "0.75rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        width: "100%",
        "&.large": {
            padding: "0.4rem 0.75rem",
        },
        "&.small": {
            width: "80px",
            padding: "0.4rem 0",
        },
        "&.dark-green": {
            backgroundColor: theme.palette.success.main,
            border: `1px solid #${theme.palette.success.main}`,
            fontWeight: 700,
            color: "#ffffff",
        },
        "&.light-green": {
            backgroundColor: theme.palette.success.light,
            border: `1px solid #${theme.palette.success.light}`,
            color: "#000000",
        }
    },
}));

const ViewpointBox = styled(Box)<BoxProps>(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    overflowY: "hidden",
    backgroundColor: "#ffffff",
    padding: "20px",
    borderRadius: "20px",
    "& #responseMeta": {
        display: "none"
    },
    "& #navTabs": {
        borderBottom: "1px solid #c9c9c9",
        "& .navTab": {
            border: "1px solid #ffffff",
            borderBottom: "none",
            color: "rgba(0, 0, 0, 0.6)",
            "&:hover": {
                color: "#000000"
            }
        },
        "& .navTab.selected": {
            backgroundColor: "#f8f9fa",
            border: "1px solid #c9c9c9",
            borderBottom: "none",
            color: "#000000",
        },
    },
    "& #contentContainer": {
        backgroundColor: "#ffffff",
        "& .answerSummary": {
            padding: 0
        }
    },
    "& .executive-profile": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "start",
        marginBottom: "40px",
        gap: "20px",
        padding: "20px",
    },
    [theme.breakpoints.down('md')]: {
        "& #navTabs .navTab": {
            borderLeft: "1px solid #c9c9c9",
            borderRight: "1px solid #c9c9c9",
        }
    },
}));

const TextIcon = styled("span")(({ theme }) => ({
    fontFamily: "Courier, monospace",
    fontSize: "1.2rem",
    lineHeight: "1rem",
    fontWeight: 700,
}));


export const ExecutiveViewpoint = ({ project, viewpoint, initiator = "deal", viewpointHtml }: any) => {
    const [respondentHtml, fetched] = viewpointHtml ? [viewpointHtml, true] : useGetRespondentHtml(viewpoint.uuid, project);
    const { userContext } = useUserContext();
    const { trackPage } = useAnalytics();

    useEffect(() => {
        if (userContext.fetched && userContext.dbUser) {
            trackPage(`deal page - winning - viewpoints - executive`, { organization: project.organization.tag, project: project.tag, role: userContext.projectMemberships.role, viewpoint: viewpoint.uuid, viewpoint_name: viewpoint.name });
        }
    }, [userContext]);

    return (
        <ViewpointBox>
            <Box className="executive-content" sx={{ overflowY: "scroll", height: "100%" }}>
                <Box className="executive-profile">
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "start",
                            alignItems: "start",
                            gap: "20px",
                            padding: "0 20px",
                        }}
                    >
                        <DealProfileImage viewpoint={viewpoint} size="medium" />

                        <Box className="viewpoint-profile-details"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "left",

                            }}>
                            <Typography sx={{ fontSize: "1.1rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
                            <Typography sx={{
                                fontSize: "1.1rem", textAlign: "left",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                marginBottom: "0.5rem"
                            }}>{viewpoint.title}</Typography>
                            <DealCompany viewpoint={viewpoint} style="left" />

                        </Box>
                    </Box>
                    <SummaryContainer>
                        <DemandSummary>
                            <Typography className={`summary-pill large ${demandOptions.find((option) => option.value === viewpoint.demand)?.class}`}>{demandOptions.find((option) => option.value === viewpoint.demand)?.label} Demand</Typography>
                        </DemandSummary>
                        <ViewpointSummary>
                            <Typography className="summary-header">Interest</Typography>
                            <Box className="summary-pills">
                                <Typography className={`summary-pill ${viewpoint.interest_beta || viewpoint.interest_demo ? "highlighted" : "gray"}`}>Demo</Typography>
                                <Typography className={`summary-pill ${viewpoint.interest_investor ? "highlighted" : "gray"}`}>Invest</Typography>
                                <Typography className={`summary-pill ${viewpoint.interest_advisor ? "highlighted" : "gray"}`}>Advise</Typography>
                            </Box>
                        </ViewpointSummary>
                    </SummaryContainer>
                </Box>
                {fetched && respondentHtml && typeof respondentHtml === 'object' ?
                    <div dangerouslySetInnerHTML={respondentHtml} />
                    :
                    <Loading />
                }
            </Box>
        </ViewpointBox>
    );
}

const LeadProfile = ({ viewpoint }: any) => {
    return (
        <iframe src={viewpoint.linkedin} style={{ width: "d100%", height: "100%" }}></iframe>
    )
}

const ProfileFaceGrid = ({ viewpoint, setOpen, user, type = "expert" }: any) => {

    return (
        <Tooltip title={!viewpoint.included_in_analysis && type === "expert" && `This viewpoint is not included in the Analysis, because it was added after the Analysis was generated or they are not in the ICP.`} placement="top">
            <Box key={viewpoint?.uuid}
                className={`viewpoint-profile face-grid`}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "center",
                    padding: "1rem",
                    color: "black",
                    cursor: "pointer",
                    position: "relative",
                    width: "110px",
                    gap: "1rem",
                    "& img": {
                        opacity: type === "lead" || !viewpoint.included_in_analysis ? 0.5 : 1,
                        filter: type === "lead" || !viewpoint.included_in_analysis ? "grayscale(1)" : "none"
                    },
                    "& img.li-icon": {
                        filter: "none",
                        opacity: 1
                    },
                    "&.excluded": {
                        filter: "grayscale(1)",
                        opacity: 0.5
                    }
                }}
                onClick={() => { type === "expert" && setOpen(true) }}>
                <DealProfileImage viewpoint={viewpoint} />
                <Box className="viewpoint-profile-details"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "110px"

                    }}>
                    <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{viewpoint.name}</Typography>
                    <Typography sx={{
                        fontSize: "0.8rem", textAlign: "center", overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: "2",
                        WebkitBoxOrient: "vertical"
                    }}>{viewpoint.title}</Typography>
                    <Typography sx={{ fontSize: "0.8rem", textAlign: "center", fontWeight: "700" }}>{viewpoint.company_name}</Typography>

                </Box>

                {user?.priorVisit && viewpoint.published_at > user.priorVisit &&
                    <Box sx={{ position: "absolute", top: 12, left: 10, backgroundColor: "#F0DE42", color: "black", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                        New
                    </Box>
                }
            </Box>
        </Tooltip>
    )
}

const ProfileViewpointGrid = ({ viewpoint, setOpen, user }: any) => {

    return (
        <Box key={viewpoint?.uuid}
            className={`viewpoint-profile viepwoint-grid display-box white`}
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "start",
                padding: "20px",
                backgroundColor: "white",
                color: "black",
                cursor: "pointer",
                marginBottom: "0",
                position: "relative",
            }}
            onClick={() => setOpen(true)}>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "start",
                        marginBottom: "1rem",
                        gap: "1rem"
                    }}
                >
                    <DealProfileImage viewpoint={viewpoint} size="small" />

                    <Box className="viewpoint-profile-details"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "left",
                            width: "200px"

                        }}>
                        <Typography sx={{ fontSize: "0.8rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
                        <Typography sx={{
                            fontSize: "0.8rem", textAlign: "left", overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical"
                        }}>{viewpoint.title}</Typography>
                        <Typography sx={{ fontSize: "0.8rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.company_name}</Typography>

                    </Box>
                </Box>
                <Box sx={{ position: "relative", paddingLeft: "20px", marginBottom: "20px" }}>
                    <FormatQuote sx={{
                        position: "absolute",
                        top: -5,
                        left: -10,
                        fontSize: "3rem",
                        color: "#e0e0e0",
                        zIndex: 0,
                        opacity: 0.5,
                        transform: "rotate(180deg)"
                    }} />
                    <Typography sx={{
                        fontStyle: "italic",
                        fontWeight: "300",
                        position: "relative",
                        zIndex: 1,
                        padding: "5px 0 0 10px"
                    }}>
                        {viewpoint.highlight_quote}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "1rem",
                    width: "100%",
                    gap: "10px",
                }}
            >
                <DemandSummary>
                    <Typography className={`summary-pill large ${demandOptions.find((option) => option.value === viewpoint.demand)?.class}`}>{demandOptions.find((option) => option.value === viewpoint.demand)?.label} Demand</Typography>
                </DemandSummary>
                <ViewpointSummary>
                    <Typography className="summary-header">Interest</Typography>
                    <Box className="summary-pills">
                        <Typography className={`summary-pill ${viewpoint.interest_beta || viewpoint.interest_demo ? "highlighted" : "gray"}`}>Demo</Typography>
                        <Typography className={`summary-pill ${viewpoint.interest_investor ? "highlighted" : "gray"}`}>Invest</Typography>
                        <Typography className={`summary-pill ${viewpoint.interest_advisor ? "highlighted" : "gray"}`}>Advise</Typography>
                    </Box>
                </ViewpointSummary>

            </Box>
            {user?.priorVisit && viewpoint.published_at > user.priorVisit &&
                <Box sx={{ position: "absolute", top: 10, left: 10, backgroundColor: "#F0DE42", color: "black", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                    New
                </Box>
            }
            <Box sx={{ position: "absolute", top: 20, right: 20, fontSize: "1.2rem", color: "#c9c9c9" }}>
                {viewpoint.has_video ?
                    <FontAwesomeIcon icon="film" /> :
                    viewpoint.has_audio ? <FontAwesomeIcon icon="volume-high" />
                        : <TextIcon>T</TextIcon>
                }
            </Box>
        </Box>

    )
}

const ProfileViewpointList = ({ viewpoint, setOpen, user }: any) => {

    return (

        <TableRow key={viewpoint?.uuid} onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
            <TableCell>
                <Typography sx={{ fontSize: "1.2rem", color: "#c9c9c9" }}>
                    {viewpoint.has_video ?
                        <FontAwesomeIcon icon="film" /> :
                        viewpoint.has_audio ? <FontAwesomeIcon icon="volume-high" />
                            : <TextIcon>T</TextIcon>
                    }
                </Typography>
            </TableCell>
            <TableCell>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", gap: "1rem", position: "relative" }}>
                    <DealProfileImage viewpoint={viewpoint} size="small" />
                    <Box>
                        <Typography sx={{ fontSize: "0.8rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
                        <Typography sx={{
                            fontSize: "0.8rem", textAlign: "left", overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical"
                        }}>{viewpoint.title}</Typography>
                    </Box>
                    {user?.priorVisit && viewpoint.published_at > user.priorVisit &&
                        <Box sx={{ position: "absolute", top: 0, left: 0, backgroundColor: "#F0DE42", color: "black", fontSize: "0.8rem", padding: "0 0.5rem" }}>
                            New
                        </Box>
                    }
                </Box>
            </TableCell>
            <TableCell>{viewpoint.company_name}</TableCell>
            <TableCell>
                <DemandSummary>
                    <Typography className={`summary-pill small ${demandOptions.find((option) => option.value === viewpoint.demand)?.class}`} sx={{ width: "80px" }}>{demandOptions.find((option) => option.value === viewpoint.demand)?.label}</Typography>
                </DemandSummary>
            </TableCell>
            <TableCell><Typography className={viewpoint.interest_beta || viewpoint.interest_demo ? "green" : "gray"}><FontAwesomeIcon icon="circle" /></Typography></TableCell>
            <TableCell><Typography className={viewpoint.interest_investor ? "green" : "gray"}><FontAwesomeIcon icon="circle" /></Typography></TableCell>
            <TableCell><Typography className={viewpoint.interest_advisor ? "green" : "gray"}><FontAwesomeIcon icon="circle" /></Typography></TableCell>
            <TableCell>
                <Typography sx={{
                    fontStyle: "italic",
                    fontSize: "0.9rem",
                    fontWeight: "300"
                }}>
                    "{viewpoint.highlight_quote}"
                </Typography>
            </TableCell>
        </TableRow >




    )
}

const ProfileProspectList = ({ viewpoint, setOpen, user }: any) => {


    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", cursor: "pointer", gap: "1rem" }} onClick={() => setOpen(true)}>
            <DealProfileImage viewpoint={viewpoint} size="small" />
            <Box>
                <Typography sx={{ fontSize: "0.8rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
                <Typography sx={{
                    fontSize: "0.8rem", textAlign: "left", overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                }}>{viewpoint.title}</Typography>
            </Box>
        </Box>
    )

}

const ProfileAngelList = ({ viewpoint, setOpen, user }: any) => {

    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", cursor: "pointer", gap: "1rem" }} onClick={() => setOpen(true)}>
            <DealProfileImage viewpoint={viewpoint} size="small" />
            <Box>
                <Typography sx={{ fontSize: "1rem", textAlign: "left", fontWeight: "700" }}>{viewpoint.name}</Typography>
            </Box>
        </Box>
    )

}

export const ExecutiveViewpointLink = ({ project, viewpoint, text, initiator = "deal" }: { project: any, viewpoint: any, text: string, initiator?: string }) => {

    const [open, setOpen] = useState(false);
    const [activeViewpoint, setActiveViewpoint] = useState(null);

    const handleOpen = () => {
        console.log(viewpoint);
        window.location.hash = viewpoint.uuid;
        setActiveViewpoint(viewpoint);
        setOpen(true);
    }

    const handleClose = () => {
        window.location.hash = "";
        setOpen(false);
        setActiveViewpoint(null);
    }
    return (
        <>
            <span className="link" onClick={handleOpen}>{text}</span>
            <Modal open={open} onClose={handleClose}>
                <ExecutiveViewpoint project={project} viewpoint={activeViewpoint} initiator={initiator} />
            </Modal>
        </>
    )
}

export const DealExecutive = ({ project, viewpoint, style, user, initialOpen = false }: TExecutive) => {

    const [open, setOpen] = useState(initialOpen);
    const [activeViewpoint, setActiveViewpoint] = useState(initialOpen ? viewpoint : null);

    const handleOpen = () => {
        window.location.hash = viewpoint.uuid;
        setActiveViewpoint(viewpoint);
        setOpen(true);
    }

    const handleClose = () => {
        window.location.hash = "";
        setOpen(false);
        setActiveViewpoint(null);
    }

    return (
        <>
            {style === "face-grid" && <ProfileFaceGrid viewpoint={viewpoint} setOpen={handleOpen} user={user} />}
            {style === "viewpoint-grid" && <ProfileViewpointGrid viewpoint={viewpoint} setOpen={handleOpen} user={user} />}
            {style === "viewpoint-list" && <ProfileViewpointList viewpoint={viewpoint} setOpen={handleOpen} user={user} />}
            {style === "lead-grid" && <ProfileFaceGrid viewpoint={viewpoint} setOpen={handleOpen} user={user} type="lead" />}
            {style === "prospect-list" && <ProfileProspectList viewpoint={viewpoint} setOpen={handleOpen} user={user} />}
            {style === "angel-list" && <ProfileAngelList viewpoint={viewpoint} setOpen={handleOpen} user={user} />}

            {style !== "lead-grid" && open &&
                <Modal open={open} onClose={handleClose}>
                    <ExecutiveViewpoint project={project} viewpoint={activeViewpoint} />
                </Modal>
            }

        </>
    );
}
