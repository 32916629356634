import { useState } from "react";
import { Modal, DialogProps } from "@mui/material";
import { useLocation } from "react-router-dom";
import useAnalytics from "../../../../hooks/useAnalytics";
import { DealInvitationForm } from "./DealInvitationForm";
import { ModalBox } from "../../../../components/ModalBox";

type TProject = {
    project: any
}

type TProjectShareComponent = TProject & {
    role: any,
    active: any,
    setActive: any,
    reloadInvitations: any
}

export const DealInvitationControl = ({ project, role, active, setActive, reloadInvitations }: TProjectShareComponent) => {

    const { state } = useLocation();
    const { trackEvent } = useAnalytics();

    const [invitationForm, setInvitiationForm] = useState({
        inviteeEmails: "",
        submitted: false,
        showWelcome: state?.invitationAccepted ?? false,
        processedInvitations: [],
        inviteeRole: role === "contributor" || role === "admin" ? "contributor" : "guest"
    });

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        setActive(false);
        reloadInvitations();
    }

    return (
        <div>
            <Modal
                open={active}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="invitation-container"
            >
                <ModalBox sx={{ width: "900px"}}>
                    <h5>Invite Team Members</h5>
                    { project.status === "draft" && <p>Invite any {project.name} or {project.organization.name} team members that can help complete the setup.</p> }

                    { project.status !== "draft" && <p>Invite any {project.name} or {project.organization.name} team members to view {project.name} insights and opportunities.</p> }
                    <DealInvitationForm project={project} role={role} setActive={setActive} reloadInvitations={reloadInvitations}/>
                
                </ModalBox>
            </Modal>
        </div>
    )
}