import React, { useEffect, useState, useContext } from 'react';
import { Box, BoxProps, Button, Checkbox, Dialog, DialogProps, FormControlLabel, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import ClientProvider from '../../../modules/ClientProvider';
import { useNavigate, useParams } from 'react-router-dom';
import { useGuestAuthContext } from './GuestContext';
import { styled } from '@mui/system';
import { DealSidebar } from '../../deal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from "../../../assets/images/brightloop-logo.svg";
import zIndex from '@mui/material/styles/zIndex';
import { DealExecutive } from '../../deal/components/DealExecutive';
import NotFound from '../../../components/NotFound';
import Loading from '../../../components/Loading';
import { DocumentTitle } from '../../../components/DocumentTitle';


const CollaborationDialog = styled(Dialog)(({ theme }) => ({
    backdropFilter: "blur(5px)",
    "& .MuiDialog-paper": {
        width: "650px",
        borderRadius: "8px",
        padding: "2rem",
        backgroundColor: "#f8f9fa",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        overflow: "hidden", // Ensure content doesn't overflow
        "& .background-image": {
            position: "absolute",
            opacity: "0.3",
            top: "20px",
            right: "-30px",
            width: "90%",
            height: "auto",
            zIndex: 0, // Ensure it is behind other elements
        },
        "& img.logo-image": {
            maxHeight: "34px",
            zIndex: 1, // Ensure it is above the background image
            "&.brightloop": {
                maxHeight: "24px",
            },
        },
        "& h5": {
            paddingBottom: "0",
            borderBottom: "none",
            fontWeight: 700,
        },
        "& .bottom-row": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            //width: "100%",
            //marginTop: "1rem",
            //zIndex: 100,
            //position: "relative",
            "& button": {
                padding: "0.5rem 1.5rem",
                borderRadius: "0.375rem",
                backgroundColor: "#1a5dbd",
                border: "2px solid #1a5dbd",
                color: "#ffffff",
                "&:hover": {
                    backgroundColor: "#4d8ae0",
                    color: "#ffffff",
                },
            },
        },
        "& label": {
            marginBottom: 0,
        }
    }
}));

const DealSidebarBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: "210px",
    position: "fixed",
    top: 0,
    left: 0,
    overflow: "hidden",
    backgroundColor: "#e1e6eb",
    color: "#000000",
    height: "100%",
    padding: "110px 0 20px",
    zIndex: "999",
    transition: "width 0.5s",
    flexGrow: 1,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    "& p, a": {
        display: "block",
        padding: "10px 20px 10px 40px",
        fontSize: "1rem",
        margin: 0,
        textDecoration: "none",
        color: "#000000",
        "&:hover": {
            backgroundColor: "#d3d8dd",
        },
        "&.disabled": {
            color: "#667788",
            cursor: "not-allowed",
            "&:hover": {
                backgroundColor: "#e1e6eb",
            },
        },
        "&.active": {
            backgroundColor: "#667788",
            color: "#ffffff",
            "& span": {
                color: "#ffffff",
            }
        },
        "& .link-stats": {
            marginLeft: "5px",
            color: "#667788",
        }
    },
    "& .tab-section-header": {
        padding: "10px 20px 10px 40px",
        marginTop: "20px",
        color: "#667788",
        fontWeight: 500,
        display: "flex",
        flexDirection: "row",
        "&::after": {
            borderBottom: "1px solid #667788",
            content: '""',
            flex: "1 1",
            margin: "auto",
            marginLeft: "10px",
        },
    },
    [theme.breakpoints.down('md')]: {
        width: "60px",
        "& .link-label": {
            display: "none"
        },
        "& .tab-section-header": {
            padding: "10px 0",
            margin: "0 15px",
            "& .header-label": {
                display: "none"
            },
            "&::after": {
                margin: 0,
            }
        },
        "& p, a": {
            padding: "10px 0",
            textAlign: "center",
            "& span": {
                display: "none"
            }
        }
    },
}));

const gridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
    gap: "1rem",
    padding: "1rem"
};

const ExecTabs = styled(Tabs)({
    width: "100%",
    display: "flex",
    "& .MuiTabs-indicator": {
        backgroundColor: "transparent",
        borderBottom: "1px solid #c9c9c9",
        color: "#000000"
    }
});

const ExecTab = styled(Tab)({
    borderLeft: "1px solid #ffffff",
    borderTop: "1px solid #ffffff",
    borderRight: "1px solid #ffffff",
    borderBottom: "1px solid #c9c9c9",
    fontWeight: "500",
    fontSize: "1rem",
    "&.Mui-selected": {
        color: "#000000",
        backgroundColor: "#f8f9fa",
        borderLeft: "1px solid #c9c9c9",
        borderTop: "1px solid #c9c9c9",
        borderRight: "1px solid #c9c9c9",
        borderBottom: "1px solid #c9c9c9",
        "&:hover": {
            backgroundColor: "#f8f9fa",
            color: "#000000"
        }
    },
    "&:hover": {
        backgroundColor: "#ffffff",
        color: "#000000"
    }
});


const BlurryDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
    backdropFilter: "blur(5px)",
    // other styles here...
}));


const mockViewpoints = [
    {
        uuid: "1",
        name: "John Doe",
        title: "CEO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "2",
        name: "Jane Doe",
        title: "CFO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "3",
        name: "John Smith",
        title: "CTO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "4",
        name: "Jane Smith",
        title: "COO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "5",
        name: "John Doe",
        title: "CEO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "6",
        name: "Jane Doe",
        title: "CFO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "7",
        name: "John Smith",
        title: "CTO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "8",
        name: "Jane Smith",
        title: "COO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "9",
        name: "John Doe",
        title: "CEO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "10",
        name: "Jane Doe",
        title: "CFO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "11",
        name: "John Smith",
        title: "CTO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "12",
        name: "Jane Smith",
        title: "COO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "13",
        name: "John Doe",
        title: "CEO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "14",
        name: "Jane Doe",
        title: "CFO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "15",
        name: "John Smith",
        title: "CTO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "16",
        name: "Jane Smith",
        title: "COO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "17",
        name: "John Doe",
        title: "CEO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "18",
        name: "Jane Doe",
        title: "CFO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "19",
        name: "John Smith",
        title: "CTO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "20",
        name: "Jane Smith",
        title: "COO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "21",
        name: "John Doe",
        title: "CEO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "22",
        name: "Jane Doe",
        title: "CFO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "23",
        name: "John Smith",
        title: "CTO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    },
    {
        uuid: "24",
        name: "Jane Smith",
        title: "COO",
        company: "Acme Inc",
        interest_demo: true,
        interest_beta: true,
        interest_investor: true,
        interest_advise: true,
        image: ""
    }

]

const SectionLink = ({ icon, text }: any) => {

    return (
        <p>
            <FontAwesomeIcon icon={icon} /><span className="link-label">&nbsp;&nbsp;{text}</span>
        </p>
    );

}

export const EmailInputScreen = ({ redirectUrl, setAuthenticated }: any) => {
    const { user, isLoading, getAccessTokenSilently } = useAuth0();
    const [email, setEmail] = useState(localStorage.getItem('bl_gview_email') || "");
    const [name, setName] = useState(localStorage.getItem('bl_gview_name') || "");
    const [company, setCompany] = useState(localStorage.getItem('bl_gview_company') || "");
    const [terms, setTerms] = useState(localStorage.getItem('bl_gview_terms') === "true");
    const [error, setError] = useState("");
    const { setToken } = useGuestAuthContext();
    const [requested, setRequested] = useState(false);
    const [project, setProject] = useState<any>();
    const [autoSubmit, setAutoSubmit] = useState(false);
    const [linkError, setLinkError] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const { shareLinkId } = useParams();

    const navigate = useNavigate();

    // TODO: Auto submit if hash is present
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const hashParts = hash.split("#");
            if (hashParts.length > 1) {
                const hashText = hashParts[1];
                if (hashText === "approved") {
                    setAutoSubmit(true);
                    window.history.replaceState({}, document.title, window.location.pathname + window.location.search);
                }
            }
        }
    }, []);

    useEffect(() => {
        const fetchProject = async () => {
            const client = ClientProvider.provideGuestClient();
            const { data, error } = await client.getShareLinkData(shareLinkId || "");

            if (error) {
                console.error(error);
                setLinkError(true);
                return;
            }

            setProject(data.project);
        };

        if (shareLinkId) {
            fetchProject();
        }
    }, [shareLinkId]);

    useEffect(() => {
        if (!isLoading && user) {
            setEmail(user.email ?? "");
            setName(user.name ?? "");
            setCompany(user.company ?? "");
        }
    }, [isLoading, user]);

    useEffect(() => {
        if (autoSubmit && email && name && company && terms) {
            handleSubmit();
        }
    }, [autoSubmit]);

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCompany(event.target.value);
    }

    const handleSubmit = async () => {
        if (!email) {
            setError('Email is required');
            return;
        }

        if (!name) {
            setError('Name is required');
            return;
        }

        if (!company) {
            setError('Company is required');
            return;
        }

        if (!terms) {
            setError('Please agree not to share this.');
            return;
        }

        try {
            setSubmitting(true);
            const client = ClientProvider.provideGuestClient();
            const { data, error } = await client.login(email, name, company, shareLinkId || "");

            if (error) {
                setError(error.message);
                return;
            }
            if (data.message === "Request Sent") {
                setRequested(true);
                setSubmitting(false);
                localStorage.setItem('bl_gview_email', email);
                localStorage.setItem('bl_gview_name', name);
                localStorage.setItem('bl_gview_company', company);
                localStorage.setItem('bl_gview_terms', terms.toString());
                return;
            }

            const jwtToken = data.token;
            setToken(jwtToken);
            localStorage.setItem('jwtToken', jwtToken);
            localStorage.setItem('welcome', "guest");
            setAuthenticated(true);

        } catch (error) {
            setError('Failed to update email');
        }
    };

    if (linkError) {
        return (
            <NotFound/>
        );
    }

    return (
        <>
            { project && <DocumentTitle pageTitle={`${project?.name} - Guest Access`} /> }
            <aside className="page-sidebar">
                <DealSidebarBox>
                    <div className="deal-tabs">
                        <SectionLink icon="home" text="Executives" />

                        <div className="tab-section-header"><span className="header-label">Insights</span></div>
                        <SectionLink icon="users" text="Viewpoints" />
                        <SectionLink icon="lightbulb" text="Analysis" />

                        <div className="tab-section-header"><span className="header-label">Opportunities</span></div>
                        <SectionLink icon="chart-line" text="Prospects" />
                        <SectionLink icon="hand-holding-dollar" text="Angels" />
                        <SectionLink icon="handshake-angle" text="Advisors" />
                        <SectionLink icon="building-user" text="Candidates" />
                    </div>
                </DealSidebarBox>
            </aside>
            <main className="page-main deal-page sidebar">
                <Box sx={{ marginBottom: "40px" }}>
                    <h2>Meet your Executives</h2>
                    <div>This is some text</div>
                </Box>
                <Box>
                    <ExecTabs variant="fullWidth" value={0}>
                        <ExecTab label={<span><FontAwesomeIcon icon="users"></FontAwesomeIcon> Gave Viewpoints</span>}></ExecTab>
                        <ExecTab label={<span><FontAwesomeIcon icon="chart-line"></FontAwesomeIcon> Want to Demo/Trial </span>}></ExecTab>
                        <ExecTab label={<span><FontAwesomeIcon icon="hand-holding-dollar"></FontAwesomeIcon> Want to Invest </span>}></ExecTab>
                        <ExecTab label={<span><FontAwesomeIcon icon="handshake-angle"></FontAwesomeIcon> Want to Advise </span>}></ExecTab>
                    </ExecTabs>
                </Box>
                <Box className="viewpoints-facegrid" sx={gridStyle}>
                    {mockViewpoints?.map((viewpoint: any) => (
                        <DealExecutive project={project} viewpoint={viewpoint} user={user} style="face-grid" key={viewpoint?.uuid} />
                    ))}
                </Box>
            </main>
            <CollaborationDialog open={true}>
                {requested &&
                    <Box className="splash-content">

                        <Typography variant="h5">Request Sent</Typography>
                        <Typography sx={{ marginTop: "20px" }}>You'll receive an email once {project.organization.name} approves your request.</Typography>
                    </Box>
                }
                { submitting &&
                    <Box className="splash-content" sx={{ width: "100%"}}>
                        <Typography variant="h5">Processing...</Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", marginTop: "20px", width: "100%" }}>
                                <Loading />
                            </Box>
                        </Box>
                    }
                {!requested && !submitting &&
                    <>
                        <img src="/images/illustrations/crowd-arrow.svg" className="background-image" />
                        <Box sx={{ width: "100%", marginBottom: "20px" }}>
                            <Typography variant="h5" sx={{ margin: "0 0 1rem", fontSize: "1.2rem" }}>Enter your contact info to gain access to insights and opportunities for</Typography>
                            {project?.logo_url ? <img className="logo-image" src={project?.logo_url} /> : project?.company?.logo_url ? <img className="logo-image" src={project?.company?.logo_url} /> : <Typography variant="h5">{project?.name}</Typography>}

                            {false && <>
                                <Typography variant="h5" sx={{ marginBottom: "20px" }}>Provide your contact information to gain access to insights and opportunities generated by</Typography>
                                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                                    {project?.organization?.logo_url ? <img className="logo-image" src={project?.organization?.logo_url} /> : <Typography variant="h5">{project?.organization?.name}</Typography>}
                                    <Typography variant="h5">for</Typography>
                                    {project?.logo_url ? <img className="logo-image" src={project?.logo_url} /> : project?.company?.logo_url ? <img className="logo-image" src={project?.company?.logo_url} /> : <Typography variant="h5">{project?.name}</Typography>}
                                </Box>
                            </>}
                        </Box>

                        <Box className="splash-content" sx={{ width: "100%", justifyContent: "flex-end" }}>
                            <Stack spacing={2} sx={{ width: "100%" }}>
                                <TextField
                                    label="Full Name"
                                    type="text"
                                    name="name"
                                    size="small"
                                    value={name}
                                    onChange={handleNameChange}
                                    required
                                />
                                <TextField
                                    label="Email Address"
                                    type="email"
                                    name="email_address"
                                    size="small"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <TextField
                                    label="Company"
                                    type="text"
                                    name="company"
                                    size="small"
                                    value={company}
                                    onChange={handleCompanyChange}
                                    required
                                />
                                {error && <Typography color="error">{error}</Typography>}
                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <Box className="bottom-row">
                                        <Box>
                                            <Typography variant="body2">Powered by</Typography>
                                            <img className="logo-image brightloop" src={logo.toString()} />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'flex-end', alignItems: 'center'
                                    }}>
                                        <FormControlLabel control={<Checkbox checked={terms} onChange={() => setTerms(!terms)} />} label="I agree not to share this." />

                                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                                            Submit
                                        </Button>
                                    </Box>
                                </Box>
                            </Stack>

                        </Box>

                    </>
                }
            </CollaborationDialog>
        </>
    )
};