import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    typography: {
        "fontFamily": `"Red Hat Display",ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    },
    palette: {
        success: {
            main: "#8bc34a",
            light: "#cddc39",
        },
        primary: {
            main: "#1a5dbd",
            light: "#4d8ae0",
            dark: "#1a5dbd",
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: "Red Hat Display",ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
          font-style: normal;
          font-display: swap;
          text-transform: none;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: "64px",
                    [createTheme().breakpoints.up('md')]: {
                        padding: "0 40px",
                    },
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: "rgba(102, 119, 136, 0.66)",
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                root: {
                    '& .MuiBackdrop-root': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    "& > .MuiBox-root": {
                        boxShadow: "0 0  20px rgba(0, 0, 0, 0.5)",
                        "&:focus": {
                            outline: "none",
                        },
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#f8f9fa",
                    border: "1px solid #ffffff",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    left: "50%",
                    maxWidth: "80%",
                    padding: "30px",
                    position: "absolute",
                    top: "50px",
                    transform: "translate(-50%, 0%)",
                    width: "500px",
                    "& h5": {
                        borderBottom: "1px solid #c9c9c9",
                        padding: "0 0 1rem",

                    },
                },
            },
        },
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides:
            {
                root: {
                    textTransform: "none",
                    fontWeight: "700",
                    fontSize: "1.2rem",
                    borderBottom: "1px solid #e0e0e0",
                    fontColor: "#000000",
                    "&:focus": {
                        outline: "none",
                    },

                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: "100%",
                    "& .MuiInputBase-root": {
                        width: "100%",
                        backgroundColor: "#ffffff",
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: "0.375rem",
                    fontWeight: "700",
                    borderWidth: "2px",
                    fontSize: "1rem",
                    "&:focus": {
                        outline: "none",
                    }
                },
                contained: {
                    //textTransform: "none",
                    //fontWeight: "700",
                    //fontSize: "1rem",
                    //borderRadius: "0.375rem",
                    //padding: "0.5rem 1.5rem",
                    //backgroundColor: "#1a5dbd",
                    //border: "2px solid #1a5dbd",
                    //color: "#ffffff",
                    "&:hover": {
                        //backgroundColor: "#4d8ae0",
                        color: "#ffffff",
                    },
                },
                outlined: {
                    backgroundColor: "#ffffff",
                    borderWidth: "2px",
                    "&:hover": {
                        borderWidth: "2px",
                    }
                }
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 0,
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    width: "50%",
                    padding: "0.5rem",
                    borderRadius: "0.375rem",
                    backgroundColor: "#ffffff",
                    border: "1px solid #c9c9c9",
                    color: "#000000",
                    "&:hover": {
                        backgroundColor: "#f9f9f9",
                    },
                    "&:focus": {
                        outline: "none",
                    },
                    "&.Mui-selected": {
                        //backgroundColor: "#1a5dbd",
                        //color: "#ffffff",
                        "&:hover": {
                            //backgroundColor: "#4d8ae0",
                        },
                    }
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    "&.nav-dropdown-menu ul": {
                        border: "1px solid #c9c9c9",
                        backgroundColor: "#ffffff",
                        minWidth: "100px",
                        padding: "0",
                        "& li": {
                            borderBottom: "1px solid #c9c9c9",
                            padding: "7px 25px",
                            "&.nav-dropdown-org-selector": {
                                backgroundColor: "#f0f8ff",
                                "&.active": {
                                    backgroundColor: "#007bff",
                                    color: "#ffffff",
                                },
                            },
                            "&.has-link": {
                                padding: 0,
                            }
                        },
                    },
                },
                paper: {
                    backgroundColor: "#f8f9fa",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff",
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    "&.admin-table td": {
                        fontSize: "1rem",
                    },
                },
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    "& th": {
                        fontWeight: "bold",
                    },
                },
            },
        },
    }

});