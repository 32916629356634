import { callExternalApi } from "../../services/external-api.service";

class ProjectHttpClient {

    _apiServerUrl;

    constructor(apiServerUrl = process.env.REACT_APP_API_ORIGIN) {
        this._apiServerUrl = apiServerUrl;
    }

    async start(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/start`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectData(projectTag: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectTag}`,
            method: "GET",
            headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    };

    async getRespondantsData(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/respondents/${projectId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getLeadsData(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/leads`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getCollaborationToken(accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/user/self/collaboration/token`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async createInvitations(organizationId: number, projectId: number, invitees: any[], note: string, role: string, type: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/invitations`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                projectId,
                organizationId,
                invitees,
                note,
                role,
                type
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectAssets(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/assets`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async createAsset(projectId: number, assetData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/asset`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: assetData
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateAsset(projectId: number, assetId: number, assetData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/asset/${assetId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: assetData
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteAsset(projectId: number, assetId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/asset/${assetId}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectMembers(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/members`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectInvitations(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitations`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectShareLinks(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/projectShareLinks`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectShareLink(projectId: number, shareLinkId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/projectShareLink/${shareLinkId}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateProjectShareLink(projectId: number, shareLinkId: number, shareLinkData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/projectShareLink/${shareLinkId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: shareLinkData
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async removeMemberFromProject(projectId: number, memberUserId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/member/${memberUserId}`,
            method: "DELETE",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }
    
    async updateMemberRole(projectId: number, memberUserId: number, role: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/member/${memberUserId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                role: role
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateInvitationRole(projectId: number, invitationId: number, role: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitation/${invitationId}`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                invitee_role: role
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async resendInvitation(projectId: number, invitationId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/invitation/${invitationId}/resend`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async sendAngelMessage(projectId: number, viewpointId: number, message: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/message/${viewpointId}`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                subject: message.subject ?? "",
                headline: message.headline ?? "",
                message: message.message ?? "",
                type: "ANGEL"
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async sendProspectMessage(projectId: number, viewpointId: number, message: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/message/${viewpointId}`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: {
                subject: message.subject ?? "",
                headline: message.headline ?? "",
                message: message.message ?? "",
                type: "PROSPECT"
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async generateContributorLink(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/contributorLink`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };
            
        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getProjectDemoSchedulers(projectId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/schedulers`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async createDemoScheduler(projectId: number, demoSchedulerData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler`,
            method: "POST",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: demoSchedulerData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async updateDemoScheduler(projectId: number, type: string, demoScheduleData: any, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler/${type}`,
            method: "PUT",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            data: demoScheduleData
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async deleteDemoScheduler(projectId: number, demoScheduleId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler/${demoScheduleId}`,
            method: "DELETE",   
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async getDemoScheduler(projectId: number, schedulerType: string, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/scheduler/${schedulerType}`,
            method: "GET",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async hideProspect(projectId: number, viewpointId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/viewpoint/${viewpointId}/hideProspect`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }

    async unhideProspect(projectId: number, viewpointId: number, accessToken: any) {
        const config = {
            url: `${this._apiServerUrl}/project/${projectId}/viewpoint/${viewpointId}/unhideProspect`,
            method: "PATCH",
            headers: {
                "content-type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            }
        };

        const { data, error } = await callExternalApi({ config });
        
        return {
            data: data || null,
            error,
        };
    }
}
    

export default ProjectHttpClient;