import { Box, Modal, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { createProject, getAllDataForSelf } from "../../../core/services/gutcheck-api.service";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../../features/users";
import { ModalBox } from "../../../components/ModalBox";
import Membership from "../..//users/types/Membership";


type AddDealProps = {
    organization: any,
    modalOpen: boolean,
    setModalOpen: (modalOpen: boolean) => void
}

type DealFormProps = {
    name: string,
    domain: string,
    goals: any[],
    type: string
}

const initDealForm = {
    name: "",
    domain: "",
    goals: [
        { value: "Demand", title: "Maximize Demand", subtitle: "Maximize the percentage of buyers who would purchase or use the product", id: 0, default: true },
        { value: "Differentiation", title: "Improve Differentiation", subtitle: "Maximize the percentage of buyers who see the product as unique vs. other products", id: 1, default: true },
    ],
    type: "Product description"
}

export const AddDeal = ({ organization, modalOpen, setModalOpen }: AddDealProps) => {

    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const { userContext, setUserContext } = useUserContext();
    const [ error, setError ] = useState<string | null>(null);

    const [ dealForm, setDealForm ] = useState<DealFormProps>(initDealForm);

    const handleChange = (e: any) => {
        setDealForm({
            ...dealForm,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async () => {

        if (!dealForm.name) {
            setError("Please provide a company name");
            return;
        }

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await createProject(organization.id, dealForm, accessToken);

        if (data?.project) {
            const userResponse = await getAllDataForSelf(accessToken);
            if (userResponse.data) {
                setUserContext({
                    ...userContext,
                    dbUser: userResponse.data.user,
                    projectMemberships: userResponse.data.user?.memberships?.filter((membership: Membership) => { return membership.project }) || [],
                    organizationMemberships: userResponse.data.user?.memberships?.filter((membership: Membership) => { return membership.organization }) || [],
                    fetched: true
                })
            }

            navigate(`/o/${data.project.organization.tag}/d/${data.project.tag}`);
        }
    }

    const handleClose = () => {
        setDealForm(initDealForm);
        setError(null);
        setModalOpen(false);
    }

    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalBox>
                <h5>Add a New Deal</h5>
                <p>Provide the name and domain of the company and we'll guide you through the setup process.</p>
                <TextField name="name" label="Company Name" variant="outlined" fullWidth onChange={(e) => handleChange(e)} />
                    { error && <Typography variant="caption" sx={{color: "red", marginBottom: "10px"}}>{error}</Typography> }
                    <TextField name="domain" label="Company Website (optional)" variant="outlined" fullWidth onChange={(e) => handleChange(e)}
                    InputProps={{
                        startAdornment: <Box sx={{color: "#999999"}}>https://</Box>
                    }}/>
                <button className="btn btn-primary" onClick={handleSubmit}>Next</button>
            </ModalBox>
        </Modal>
    );
}