import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from "@mui/material"
import { useOutletContext } from "react-router-dom";
import useAnalytics from "../../../hooks/useAnalytics";
import { useEffect, useState } from "react";
import Loading from "../../../components/Loading";
import { styled } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const InstructionsSection = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    marginTop: "20px",
    "& .instruction-section-header": {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        "& h6": {
            marginBottom: "0",
        },
        "& span": {
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            backgroundColor: "#1d1d1d",
            color: "#ffffff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "20px",
            fontWeight: 700
        }
    },
    "& .instruction-section-content": {
        backgroundColor: "#f5f5f5",
        padding: "20px",
        marginLeft: "50px",
        borderRadius: "5px",
    }

}));


const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'short' } as const;
    return date.toLocaleDateString('en-US', options);
};

const SydecarInstructions = ({ project }: any) => {

    const [expanded, setExpanded] = useState<string | false>(false);

    const instructions = [
        {
            question: "Am I an accredited investor?",
            answer: "There's no formal certification to be an accredited investor or documents that you need to upload - you simply have to attest that you meet one of several criteria. If you individually earn over $200K per year, or earn over $300K with your spouse or partner, or have a net worth over $1M, you likely qualify as an accredited investor. You can see the full list of criteria on the Sydecar site."
        },
        {
            question: "Do I have a pre-existing relationship with Brightloop? [Yes]",
            answer: "The short answer is yes. If you've engaged with BrightLoop, or added a viewpoint, or helped a VC vet a potential investment via BrightLoop, you've got the required pre-existing relationship and should reply 'yes'"
        },
        {
            question: "Do I have a FINCEN ID? [Probably Not]",
            answer: "FinCEN is a bureau of the U.S. Department of the Treasury that works to collect financial information. A FinCEN ID is a unique 12-digit identifying number that FinCEN issues to individuals or companies upon request that they can use instead of providing detailed personal information. Net, if you're not sure about a FinCEN ID, you're very unlikely to have one, in which case you would reply 'no'."        },
        {
            question: "What if I am not a US Citizen?",
            answer: "International citizens will need to upload additional documentation such as a copy of your passport, proof of address, or bank statement. This is similar to all fintech platforms."
        },
        {
            question: "Am I subject to Backup withholding?",
            answer: "IRS backup withholding applies when taxpayers run afoul of the IRS. Individuals who are subject to backup withholding are typically aware of it. If this doesn't apply to you, indicate no."
        },
        {
            question: "Am I exempt from the CTA? [Probably Not]",
            answer: "The Corporate Transparency Act requires certain companies to report beneficial ownership information to the government. Banks, government organizations and credit unions are exempt. If you are a individual and/or don't know that you have an exception, please leave this box blank."
        },
        {
            question: "How do I verify my identity?",
            answer: "When confirming your identity, Sydecar may use an app called Vouched to confirm your identity. This typically takes less than a minute."
        }
    ]



    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <Box>
            <p>
                BrightLoop is investing in {project.name} and managing the investments from individual executives. These investments are being pooled into a single SPV ("BrightLoop/{project.name} {formatDate(project.funding_at)}") on the {project.name} cap table. Sydecar is our back-office partner for the SPV transaction. Sydecar has managed over 1,800 SPVs with over $1 billion under administration.
            </p>
            <p>
                When you're ready to sign docs, click the <strong>LAUNCH SYDECAR</strong> button and it will pull up Sydecar in a different tab. You can view the tabs next to each other if that's helpful. The tutorial below covers frequently asked questions you may have as you go.
            </p>
            <Box className="display-box" sx={{ marginTop: "40px"}}>
            {instructions.map((instruction, index) => {
                return (
                    <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}-content`}
                            id={`panel${index}-header`}
                            className="accordion-faq-question"
                        >
                            <Typography sx={{ fontWeight: 700 }}>{instruction.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{instruction.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
            </Box>
        </Box>
    )
}

const AngellistInstructions = () => {

    return (
        <Box>
            <InstructionsSection>
                <Box className="instruction-section-header">
                    <span>1</span>
                    <h6>
                        You have an existing relationship with BrightLoop
                    </h6>
                </Box>
                <Box className="instruction-section-content">
                    <p>You'll be asked if you have a pre-existing relationship with AngelList. By taking the evaluation, you do. So go ahead and say "Yes" to that.</p>
                </Box>
            </InstructionsSection>
            <InstructionsSection>
                <Box className="instruction-section-header">
                    <span>2</span>
                    <h6>
                        Are you accredited?
                    </h6>
                </Box>
                <Box className="instruction-section-content">
                    <p>
                        You'll be asked if you are accredited. If you are not accredited, you will not be able to invest in the project through AngelList's SPV.
                    </p>
                </Box>
            </InstructionsSection>
            <InstructionsSection>
                <Box className="instruction-section-header">
                    <span>3</span>
                    <h6>
                        Required documents
                    </h6>
                </Box>
                <Box className="instruction-section-content">
                    <p>
                        You'll be asked to upload your accreditation documents. Make sure you have them ready.
                    </p>
                </Box>
            </InstructionsSection>
        </Box>
    )
}




export const EvaluationInstructions = () => {
    const { surveyResponse, loading, investmentUnlocked } = useOutletContext<any>();
    const project = surveyResponse.survey_link.project;
    const { trackSurveyResponsePage, trackSurveyResponseEvent } = useAnalytics();

    useEffect(() => {
        if (surveyResponse) {
            trackSurveyResponsePage(surveyResponse, 'expert flow - invest - instructions');
        }
    }, [surveyResponse]);

    if (loading) {
        return <Loading />
    }

    const handleSpvUrlClick = () => {
        trackSurveyResponseEvent(
            surveyResponse,
            "Lifecycle - Revenue - Clicked SPV",
            {
                survey_link_partner: surveyResponse.survey_link.partner_name,
                project: surveyResponse.survey_link.project.public_title,
                organization: surveyResponse.survey_link.project.organization.name,
                survey_link_name: surveyResponse.survey_link.name,
                survey_link_id: surveyResponse.survey_link.public_id,
                survey_link: window.location.href
            }
        );
        window.open(project.spv_platform_url, '_blank');
    }

    return (
        <>
            <Box className="flex-column left-column" sx={{gap: "20px"}}>
                <Stack spacing={2}>
                    <h5>
                        Instructions and Tips on Investing through {project.spv_platform_name}
                    </h5>

                    {project.spv_platform_name === 'Sydecar' && <SydecarInstructions project={project} />}
                    {project.spv_platform_name === 'AngelList' && <AngellistInstructions />}

                    < Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center", gap: "20px" }}>
                        <Typography variant="body1">Ready to get started?</Typography>
                        <Button variant="contained" color="primary" onClick={handleSpvUrlClick}>Launch {project.spv_platform_name}&nbsp;&nbsp;<FontAwesomeIcon icon="arrow-up-right-from-square" /></Button>
                    </Box>
                </Stack>
            </Box>
            <Box className="flex-column right-column">
                <h5>Ready to get started?</h5>
                <Button size="large" variant="contained" color="primary" onClick={handleSpvUrlClick}>Launch {project.spv_platform_name}&nbsp;&nbsp;<FontAwesomeIcon icon="arrow-up-right-from-square" /></Button>

            </Box>
        </>
    )
}