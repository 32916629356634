import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

export const NotFoundPage = () => {
  return (
    <Box className="content-layout not-found-page" sx={{ display: "flex", justifyContent: "center", height: "100vh", padding: "2rem" }}>
      <Box className="not-found-content">
        <h1 className="not-found-title">404</h1>
        <h2 className="not-found-subtitle">Oops! Page Not Found</h2>
        <p className="not-found-message">
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </p>
        <Link to="/" className="not-found-link">
          Go back to homepage
        </Link>
      </Box>
    </Box>
  );
};
