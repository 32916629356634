import { useOutletContext } from "react-router-dom";
import { useUserContext } from "../../users";
import { DealTitle } from "../components/DealTitle";
import { DemoScheduler } from "../components/prospects/DemoScheduler";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../modules/ClientProvider";
import { Box } from "@mui/material";
import { Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { CheckCircle, RadioButtonUnchecked, Settings } from "@mui/icons-material";

export const DealCalendaring = () => {
    const { project, organization, viewerRole } = useOutletContext<{ project: any, organization: any, viewerRole: any }>();
    const { userContext } = useUserContext();

    const { getAccessTokenSilently } = useAuth0();
    const [schedulerData, setSchedulerData] = useState<any | null>(null);
    const [ configMode, setConfigMode ] = useState<boolean>(false);

    const fetchSchedulerData = async () => {
        const accessToken = await getAccessTokenSilently();
        const projectClient = ClientProvider.provideProjectClient();
        const { data, error } = await projectClient.getDemoScheduler(project.id, "prospect", accessToken);
        if (error) {
            console.error('Error fetching demo scheduler data:', error);
        } else {
            setSchedulerData(data.scheduler);
            setConfigMode(!data.scheduler?.attendees?.length || !data.scheduler?.calendars?.length);
        }
    };

    useEffect(() => {

            fetchSchedulerData();

        return () => {
            setSchedulerData(null);
        };
    }, []);

    const getStatus = () => {
        if (!schedulerData) return " Configuration";
        if (!schedulerData.attendees?.length || !schedulerData.calendars?.length || !schedulerData.calendars?.find((calendar: any) => calendar.url !== "") || !schedulerData.availability_windows?.length) return "Awaiting Configuration";
        if (!schedulerData.is_active) return "Pending Activation";
        return "Active";
    };

    const handleSubmit = async () => {
        fetchSchedulerData();
    };

    return (
        <div>
            <DealTitle viewerRole={viewerRole} project={project} section="Calendar Setup" />
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: "40px"
            }}>
                <Box sx={{ flexGrow: 1 }}>
                    <DemoScheduler schedulerData={schedulerData} projectId={project.id} onSubmit={handleSubmit} />
                </Box>
                <Box sx={{ width: "300px" }}>
                    <Box className="display-box">
                        <p>
                            Before you can schedule demos, you need to set up your calendaring. Please add at least one attendee from your team, at least one calendar to check for availability, and specify your preferred time windows.
                        </p>
                        <p>
                            Once you have set up your calendaring, it takes up to one business day to activate.
                        </p>
                        <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
                            Status: {getStatus()}
                        </Typography>

                    </Box>
                </Box>
            </Box>
        </div>
    );
}