import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, TextField } from "@mui/material";
import { AddOrganizationForm } from "../components/form-add-organization";
import { getOrganizations, deleteOrganization } from "../services/gutcheck-admin-api.service";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar, Alert } from '@mui/material';
import { DocumentTitle } from "../../../components/DocumentTitle";

type TAdminOrganizations = {
    organizations: any | null,
    filteredOrganizations: any | null,
    highlightedOrganizations: any | null,
    addOrganizationMessage: any | null,
    deleteMessage: string | null,
    deleteDialogOpen: boolean,
    selectedOrganizationId: number | null,
    snackbar: {
        open: boolean,
        message: string,
        severity: 'success' | 'error' | 'info' | 'warning' | null
    }
}

const initState: TAdminOrganizations = {
    organizations: null,
    filteredOrganizations: null,
    highlightedOrganizations: null,
    addOrganizationMessage: "",
    deleteMessage: null,
    deleteDialogOpen: false,
    selectedOrganizationId: null,
    snackbar: {
        open: false,
        message: '',
        severity: null
    }
}

export const AdminOrganizations = () => {

    const [state, setState] = useState(initState)
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const [resOrganizations] = await Promise.all([
                getOrganizations(accessToken)
            ]);

            if (resOrganizations) {
                setState({
                    ...state,
                    organizations: resOrganizations.data.organizations.filter((organization: any) => !organization.is_deleted),
                    filteredOrganizations: resOrganizations.data.organizations.filter((organization: any) => !organization.is_deleted)
                });
            }

        };

        fetchData();

        return () => { };
    }, []);

    if (!state.organizations) {
        return <></>
    }

    const handleAddOrganizationClick = async (event: any) => {
        event.target.parentElement.parentElement.classList.add("show-form");
        event.preventDefault();
        setState({
            ...state,
            addOrganizationMessage: ""
        })
    }

    const handleCallback = async (organization: any) => {

        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getOrganizations(accessToken);

        if (data) {
            setState({
                ...state,
                highlightedOrganizations: organization.id,
                organizations: data.organizations,
                filteredOrganizations: data.organizations,
                addOrganizationMessage: "Organization added successfully."
            });
        } else {
            setState({
                ...state,
                addOrganizationMessage: "There was an error adding the organization"
            })
        }

        document.getElementsByClassName("add-object-wrapper")[0].classList.remove("show-form");
    }

    const handleSearch = (event: any) => {

        const queryString = event.target.value;
        const filteredOrganizations = state.organizations.filter((organization: any) => {
            return organization.name.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
        });

        setState({
            ...state,
            filteredOrganizations: filteredOrganizations
        });

    };

    const handleDeleteClick = (organizationId: number) => {
        setState({
            ...state,
            deleteDialogOpen: true,
            selectedOrganizationId: organizationId
        });
    };

    const handleDeleteConfirm = async () => {
        const accessToken = await getAccessTokenSilently();
        const { error } = await deleteOrganization(state.selectedOrganizationId!, accessToken);

        if (!error) {
            const { data } = await getOrganizations(accessToken);
            setState({
                ...state,
                organizations: data.organizations.filter((organization: any) => !organization.is_deleted),
                filteredOrganizations: data.organizations.filter((organization: any) => !organization.is_deleted),
                deleteDialogOpen: false,
                selectedOrganizationId: null,
                snackbar: {
                    open: true,
                    message: "Organization deleted successfully",
                    severity: "success"
                }
            });
        } else {
            setState({
                ...state,
                deleteDialogOpen: false,
                selectedOrganizationId: null,
                snackbar: {
                    open: true,
                    message: "Error deleting organization",
                    severity: "error"
                }
            });
        }
    };

    const handleDeleteCancel = () => {
        setState({
            ...state,
            deleteDialogOpen: false,
            selectedOrganizationId: null
        });
    };

    const handleSnackbarClose = () => {
        setState({
            ...state,
            snackbar: {
                ...state.snackbar,
                open: false
            }
        });
    };

    return (
        <Fragment>
            <DocumentTitle pageTitle="Admin - Organizations" />
            {state.deleteMessage && (
                <div className="alert alert-info">{state.deleteMessage}</div>
            )}
            <div className="flex-box-wrapper">
                <div className="add-object-wrapper">
                    <div className="add-object-toggle">
                        <div className="add-object-message">{state.addOrganizationMessage}</div>

                        <Button 
                            variant="contained"
                            onClick={handleAddOrganizationClick}
                        >
                            Add Organization
                        </Button>
                    </div>
                    <div className="add-object-form">
                        <AddOrganizationForm parentCallback={handleCallback} />
                    </div>
                </div>
                <div className="admin-list-filter">
                    <TextField 
                        label="Search" 
                        variant="outlined" 
                        size="small"
                        onChange={handleSearch}
                    />
                </div>
            </div>
            
            <TableContainer>
                <Table stickyHeader className="admin-table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Tag</TableCell>
                            <TableCell>Projects</TableCell>
                            <TableCell>Created At</TableCell>
                            <TableCell>Last Updated</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.filteredOrganizations.map((organization: any) => (
                            <TableRow 
                                key={organization.id} 
                                className={state.highlightedOrganizations && organization.id === state.highlightedOrganizations ? "highlighted" : ""}
                            >
                                <TableCell>{organization.id}</TableCell>
                                <TableCell>
                                    <Link to={`/admin/organization/${organization.id}/edit`}>
                                        {organization.name ? organization.name : "no name"}
                                    </Link>
                                </TableCell>
                                <TableCell>{organization.tag}</TableCell>
                                <TableCell>{organization.projects.length}</TableCell>
                                <TableCell>{organization.created_at}</TableCell>
                                <TableCell>{organization.updated_at}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="text"
                                        color="error"
                                        size="small"
                                        onClick={() => handleDeleteClick(organization.id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={state.deleteDialogOpen}
                onClose={handleDeleteCancel}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this organization?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={state.snackbar.open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={state.snackbar.severity || 'info'}
                    sx={{ width: '100%' }}
                >
                    {state.snackbar.message}
                </Alert>
            </Snackbar>

        </Fragment>
    );

}

export default AdminOrganizations;
