import NavBar from "../../../components/NavBar";
import { Box, styled, ThemeProvider } from "@mui/material";
import { theme } from "../../../theme/theme";
import { useEffect, useState } from "react";
import { useUserContext } from "../../users";
import ClientProvider from "../../../modules/ClientProvider";
import { useGuestAuthContext } from "..";
import { useDealContext } from "../../deal";
import { GuestBanner } from "./GuestBanner";
import useAnalytics from "../../../hooks/useAnalytics";
import Membership from "../../users/types/Membership";

type PageProps = {
    children: any
}

export const AppGuestLayout = ({ children }: PageProps) => {

    const { userContext, setUserContext } = useUserContext();
    const { dealContext } = useDealContext();
    const { token, loading } = useGuestAuthContext();
    const [invitation, setInvitation] = useState();
    const [ invitationFetched, setInvitationFetched ] = useState(false);
    const { trackUser } = useAnalytics();

    const client = ClientProvider.provideGuestClient();

    useEffect(() => {

        const fetchUserData = async () => {

            if (!token) {
                return
            }

            const { data, error } = await client.getUserData(token);
            if (data) {
                setUserContext({
                    ...userContext,
                    dbUser: data.user,
                    projectMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.project }) || [],
                    organizationMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.organization }) || [],
                    fetched: true
                });

                trackUser(data.user);
            }
        }

        const fetchSandboxInvitation = async () => {
            if (!token) {
                return
            }

            const { data, error } = await client.getSandboxInvitation(token)
            if (data) {
                setInvitation(data.invitation);
            }
        }

        if (!loading) {
            fetchUserData();
            fetchSandboxInvitation();
        }

    }, [loading]);


    const visibleExecutives = dealContext.project?.project_share_links && (dealContext.project.project_share_links[0].filter_interest_demo || dealContext.project.project_share_links[0].filter_interest_invest || dealContext.project.project_share_links[0].filter_interest_advise) ? "engaged" : "all";

    return (
        <ThemeProvider theme={theme}>
            <div id="app" className="d-flex flex-column h-100">
                <NavBar hideUser={true} />
                {userContext.dbUser?.id &&
                    <GuestBanner />
                }
                <div className="page-wrapper guest">
                    {children}
                </div>
            </div>
        </ThemeProvider>
    );
};
//about how you can generate insights and opportunities for your own deals, <span className="link" style={{ color: "#000000", fontWeight: "bold", textDecoration: "underline"}}>click here</span> for a demo or <span className="link" style={{ color: "#000000", fontWeight: "bold", textDecoration: "underline"}}>click here</span> for a sandbox.