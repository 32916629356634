import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Select,
    IconButton,
    Typography,
    Box,
    Chip,
    Autocomplete,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Snackbar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth0 } from '@auth0/auth0-react';
import ClientProvider from "../../../../modules/ClientProvider";
import TimezoneSelect from 'react-timezone-select'
import { useNavigate, useLocation, unstable_useBlocker as useBlocker } from 'react-router-dom';

interface TimeRange {
    start: string;
    end: string;
}

interface AvailabilityWindows {
    [key: string]: {
        isAvailable: boolean;
        ranges: TimeRange[];
    };
}

interface Calendar {
    url: string;
    name: string;
}

interface DemoSchedulerData {
    id: number;
    uuid: string;
    is_active: boolean;
    timezone: string;
    calendars: Array<{
        id: number;
        uuid: string;
        url: string;
        name: string;
    }>;
    availability_windows: Array<{
        id: number;
        uuid: string;
        day_of_week: number;
        start_time: string;
        end_time: string;
    }>;
    attendees: Array<{
        id: number;
        uuid: string;
        email: string;
    }>;
    // ... other fields ...
}

const timeOptions: string[] = [
    '12:00 AM', '12:30 AM', '1:00 AM', '1:30 AM', '2:00 AM', '2:30 AM', '3:00 AM', '3:30 AM',
    '4:00 AM', '4:30 AM', '5:00 AM', '5:30 AM', '6:00 AM', '6:30 AM', '7:00 AM', '7:30 AM',
    '8:00 AM', '8:30 AM', '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
    '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM',
    '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM', '6:00 PM', '6:30 PM', '7:00 PM', '7:30 PM',
    '8:00 PM', '8:30 PM', '9:00 PM', '9:30 PM', '10:00 PM', '10:30 PM', '11:00 PM', '11:30 PM'
];

const initialAvailabilityWindows: AvailabilityWindows = {
    Sun: { isAvailable: false, ranges: [{ start: '9:00 AM', end: '5:00 PM' }] },
    Mon: { isAvailable: true, ranges: [{ start: '9:00 AM', end: '5:00 PM' }] },
    Tue: { isAvailable: true, ranges: [{ start: '9:00 AM', end: '5:00 PM' }] },
    Wed: { isAvailable: true, ranges: [{ start: '9:00 AM', end: '5:00 PM' }] },
    Thu: { isAvailable: true, ranges: [{ start: '9:00 AM', end: '5:00 PM' }] },
    Fri: { isAvailable: true, ranges: [{ start: '9:00 AM', end: '5:00 PM' }] },
    Sat: { isAvailable: false, ranges: [{ start: '9:00 AM', end: '5:00 PM' }] }
};

const initialCalendars: Calendar[] = [{ url: '', name: '' }];

const convertTo12HourFormat = (time24: string): string => {
    const [hours, minutes] = time24.split(':').map(Number);
    const period = hours >= 12 ? 'PM' : 'AM';
    const hours12 = hours % 12 || 12;
    return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
};

const convertTo24HourFormat = (time12: string): string => {
    const [time, period] = time12.split(' ');
    let [hours, minutes] = time.split(':').map(Number);
    if (period === 'PM' && hours !== 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

const getUserTimezone = (): string => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};


const InstructionsDialog = ({ open, onClose }: { open: boolean, onClose: () => void }) => {

    const [ value, setValue ] = useState('google');
    return (
        <Dialog open={open} onClose={onClose}>
            <h5>How to find your calendar URL</h5>
            <Box>
                <Tabs value={value} onChange={(_, newValue) => setValue(newValue)} variant="fullWidth" style={{ marginBottom: '16px' }} className="modal-modal-tabs" >
                    <Tab label="Google Calendar" value="google"/>
                    <Tab label="Outlook" value="outlook"/>
                </Tabs>

                { value === "google" && (
                    <List sx={{ listStyleType: 'decimal', paddingLeft: '1rem' }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary="Open Google Calendar in your browser." />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary="On the left side, find your main calendar and click on the three dots next to it." />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: 'Choose <strong>Settings and sharing</strong>.' }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Under <strong>Share with specific people or groups</strong>, add <strong>intros@brightloop.ai</strong> and select <strong>See only free/busy (hide details)</strong> from the dropdown. (Note: You can skip this step if you are already publicly sharing your calendar.)` }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Click <strong>Get shareable link</strong> and then <strong>Copy link</strong>.` }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Paste this URL into the "Calendar URL" field in the scheduler.` }} />} />
                        </ListItem>
                    </List>
                )}

                { value === "outlook" && (
                    <List sx={{ listStyleType: 'decimal', paddingLeft: '1rem' }}>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary="Open Outlook in your browser." />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary="Click on the Settings gear icon in the top right corner." />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Search for <strong>Calendar</strong> in the settings search bar.` }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Click on <strong>Shared calendars</strong>.` }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Under <strong>Publish a calendar</strong>, select the calendar you want to share.` }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Choose <strong>See only free/busy</strong> for permissions.` }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: `Click <strong>Publish</strong>.` }} />} />
                        </ListItem>
                        <ListItem sx={{ display: 'list-item' }}>
                            <ListItemText primary={`Paste this URL into the "Calendar URL" field in the scheduler.`} />
                        </ListItem>
                    </List>
                )}
            </Box>
        </Dialog>
    );
};

// Add this new component at the top of the file, outside of the DemoScheduler component
const UnsavedChangesDialog = ({ open, onClose, onConfirm, onCancel }: { open: boolean, onClose: () => void, onConfirm: () => void, onCancel: () => void }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <h5>Unsaved Changes</h5>
            <DialogContent>
                <Typography>You have unsaved changes. Are you sure you want to leave?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Stay</Button>
                <Button onClick={onConfirm} color="primary">Leave</Button>
            </DialogActions>
        </Dialog>
    );
};

const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const DemoScheduler = ({ schedulerData, projectId, onSubmit }: { schedulerData: DemoSchedulerData, projectId: number, onSubmit: () => void }) => {

    const { getAccessTokenSilently } = useAuth0();

    const [calendars, setCalendars] = useState<Calendar[]>(initialCalendars);
    const [availabilityWindows, setAvailabilityWindows] = useState<AvailabilityWindows>(initialAvailabilityWindows);
    const [attendeeEmails, setAttendeeEmails] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedTimezone, setSelectedTimezone] = useState<any>(getUserTimezone());
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const processedEmails = useRef<Set<string>>(new Set());
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
    const [showInstructionsDialog, setShowInstructionsDialog] = useState(false);
    const navigate = useNavigate();
    const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
    // Use the useBlocker hook
    const blocker = useBlocker(hasUnsavedChanges) as { state: string; proceed: () => void; reset: () => void; } | undefined;

    useEffect(() => {
        if (blocker?.state === 'blocked') {
            setShowUnsavedChangesDialog(true);
        }
    }, [blocker]);

    useEffect(() => {
        if (schedulerData) {
            const calendarData = schedulerData?.calendars?.map((cal: { url: any; name: any; }) => ({ url: cal.url, name: cal.name }))
            // Update calendars state
            setCalendars(calendarData && calendarData.length > 0 ? calendarData : initialCalendars);

            // Update availabilityWindows state
            const newAvailabilityWindows: AvailabilityWindows = Array.isArray(schedulerData.availability_windows) ? {
                Sun: { isAvailable: false, ranges: [] },
                Mon: { isAvailable: false, ranges: [] },
                Tue: { isAvailable: false, ranges: [] },
                Wed: { isAvailable: false, ranges: [] },
                Thu: { isAvailable: false, ranges: [] },
                Fri: { isAvailable: false, ranges: [] },
                Sat: { isAvailable: false, ranges: [] }
            } : initialAvailabilityWindows;

            if (Array.isArray(schedulerData?.availability_windows)) {
                schedulerData.availability_windows.forEach((window: { day_of_week: number; start_time: string; end_time: string; }) => {
                    const dayIndex = window.day_of_week;
                    if (dayIndex >= 0 && dayIndex <= 6) {
                        const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayIndex] as keyof AvailabilityWindows;
                        const start = convertTo12HourFormat(window.start_time);
                        const end = convertTo12HourFormat(window.end_time);

                        newAvailabilityWindows[day] = {
                            isAvailable: true,
                            ranges: [...(newAvailabilityWindows[day].ranges), { start, end }]
                        };
                    }
                });
            }

            setAvailabilityWindows(newAvailabilityWindows);

            // Update attendeeEmails state
            setAttendeeEmails(schedulerData?.attendees?.map((attendee: { email: string }) => attendee.email) ?? []);

            // Set the selectedTimezone from the payload or default to user's timezone
            setSelectedTimezone(schedulerData?.timezone ?? getUserTimezone());

            setHasUnsavedChanges(false);
        }
    }, [schedulerData]);


    const handleAddEmails = useCallback((emails: string[]) => {
        const newEmails = emails.filter(email => {
            const trimmedEmail = email.trim();
            return trimmedEmail && 
                   isValidEmail(trimmedEmail) && 
                   !attendeeEmails.includes(trimmedEmail) && 
                   !processedEmails.current.has(trimmedEmail);
        });

        if (newEmails.length > 0) {
            setAttendeeEmails(prev => [...prev, ...newEmails]);

            setCalendars(prev => {
                let updatedCalendars = [...prev];

                if (updatedCalendars.length === 1 && !updatedCalendars[0].url && !updatedCalendars[0].name) {
                    updatedCalendars = [];
                }

                newEmails.forEach(email => {
                    if (!updatedCalendars.some(cal => cal.name === email)) {
                        updatedCalendars.push({ url: '', name: email });
                    }
                });

                return updatedCalendars;
            });

            newEmails.forEach(email => processedEmails.current.add(email));
            setHasUnsavedChanges(true);
        }
    }, [attendeeEmails, setHasUnsavedChanges]);

    const handleDeleteEmail = (emailToDelete: string) => {
        setAttendeeEmails(prev => prev.filter(email => email !== emailToDelete));
        // Only remove the calendar if it's not the last one
        setCalendars(prev => {
            if (prev.length > 1) {
                return prev.filter(cal => cal.name !== emailToDelete);
            }
            return prev.map(cal => cal.name === emailToDelete ? { url: '', name: '' } : cal);
        });
        setHasUnsavedChanges(true);
    };

    const parseInput = (input: string): string[] => {
        return input.split(/[\s,]+/).filter(email => email.trim());
    };

    const handleSubmit = async () => {

        // Prepare payload
        const payload = {
            calendars: calendars.map(cal => ({ url: cal.url, name: cal.name })),
            availability_windows: Object.entries(availabilityWindows)
                .filter(([_, day]) => day.isAvailable)
                .flatMap(([dayName, day]) => {
                    const dayIndex = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].indexOf(dayName);
                    return day.ranges.map(range => ({
                        day_of_week: dayIndex,
                        start_time: convertTo24HourFormat(range.start),
                        end_time: convertTo24HourFormat(range.end)
                    }));
                }),
            attendees: attendeeEmails.map(email => ({ email })),
            timezone: selectedTimezone || 'UTC'
        };

        try {
            setLoading(true);
            const projectClient = ClientProvider.provideProjectClient();
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await projectClient.updateDemoScheduler(projectId, "prospect", payload, accessToken);

            if (error) {
                console.error('Error updating demo scheduler:', error);
                alert("An error occurred while updating the demo scheduler. Please try again.");
            } else {
            }
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            alert("An unexpected error occurred. Please try again.");
        } finally {
            setLoading(false);
            setHasUnsavedChanges(false);
            onSubmit();
            setShowSuccessSnackbar(true);
        }
    };

    const handleAddTimeRange = (day: string) => {
        setAvailabilityWindows(prev => {
            const lastRange = prev[day].ranges[prev[day].ranges.length - 1];
            const lastEndIndex = timeOptions.indexOf(lastRange.end);
            const newStart = timeOptions[lastEndIndex + 1] || lastRange.end;
            const newEnd = timeOptions[lastEndIndex + 2] || newStart;
            return {
                ...prev,
                [day]: {
                    ...prev[day],
                    ranges: [...prev[day].ranges, { start: newStart, end: newEnd }]
                }
            };
        });
        setHasUnsavedChanges(true);
    };

    const handleTimeChange = (day: string, index: number, field: 'start' | 'end', value: string) => {
        setAvailabilityWindows(prev => {
            const updatedRanges = [...prev[day].ranges];
            const currentRange = { ...updatedRanges[index] };
            const otherRanges = updatedRanges.filter((_, i) => i !== index);

            if (field === 'start') {
                currentRange.start = value;
            } else {
                currentRange.end = value;
            }

            // Check for overlaps
            const isOverlapping = otherRanges.some(range => {
                const rangeStart = timeOptions.indexOf(range.start);
                const rangeEnd = timeOptions.indexOf(range.end);
                const currentStart = timeOptions.indexOf(currentRange.start);
                const currentEnd = timeOptions.indexOf(currentRange.end);
                return (currentStart < rangeEnd && currentEnd > rangeStart);
            });

            if (isOverlapping) {
                return prev; // Don't update if there's an overlap
            }

            updatedRanges[index] = currentRange;
            return {
                ...prev,
                [day]: {
                    ...prev[day],
                    ranges: updatedRanges
                }
            };
        });
        setHasUnsavedChanges(true);
    };

    const getStartTimeOptions = (endTime: string, otherRanges: Array<{ start: string, end: string }>) => {
        const endIndex = timeOptions.indexOf(endTime);
        return timeOptions.slice(0, endIndex).filter(time => {
            const timeIndex = timeOptions.indexOf(time);
            return !otherRanges.some(range => {
                const rangeStartIndex = timeOptions.indexOf(range.start);
                const rangeEndIndex = timeOptions.indexOf(range.end);
                return timeIndex >= rangeStartIndex && timeIndex < rangeEndIndex;
            });
        });
    };

    const getEndTimeOptions = (startTime: string, otherRanges: Array<{ start: string, end: string }>) => {
        const startIndex = timeOptions.indexOf(startTime);
        return timeOptions.slice(startIndex + 1).filter(time => {
            const timeIndex = timeOptions.indexOf(time);
            return !otherRanges.some(range => {
                const rangeStartIndex = timeOptions.indexOf(range.start);
                const rangeEndIndex = timeOptions.indexOf(range.end);
                return timeIndex > rangeStartIndex && timeIndex <= rangeEndIndex;
            });
        });
    };

    const handleRemoveTimeRange = (day: string, index: number) => {
        setAvailabilityWindows(prev => ({
            ...prev,
            [day]: {
                ...prev[day],
                ranges: prev[day].ranges.filter((_, i) => i !== index)
            }
        }));
        setHasUnsavedChanges(true);
    };

    const handleDayToggle = (day: string) => {
        setAvailabilityWindows(prev => {
            const updatedDay = { ...prev[day] };
            updatedDay.isAvailable = !updatedDay.isAvailable;

            // If the day is being enabled and has no ranges, add a default range
            if (updatedDay.isAvailable && updatedDay.ranges.length === 0) {
                updatedDay.ranges = [{ start: '9:00 AM', end: '5:00 PM' }];
            }

            return { ...prev, [day]: updatedDay };
        });
        setHasUnsavedChanges(true);
    };

    const handleAddCalendar = () => {
        setCalendars(prev => [...prev, { url: '', name: '' }]);
        setHasUnsavedChanges(true);
    };

    const handleRemoveCalendar = (index: number) => {
        setCalendars(prev => {
            // If it's the last calendar, just clear it instead of removing
            if (prev.length === 1) {
                return [{ url: '', name: '' }];
            }
            return prev.filter((_, i) => i !== index);
        });
        // Don't remove the attendee when removing a calendar
        setHasUnsavedChanges(true);
    };

    const handleCalendarChange = (index: number, field: 'url' | 'name', value: string) => {
        setCalendars(prev => prev.map((calendar, i) =>
            i === index ? { ...calendar, [field]: value } : calendar
        ));
    };

    const handleTimezoneChange = (timezone: any) => {
        setSelectedTimezone(timezone);
        setHasUnsavedChanges(true);
    };

    const selectStyle = {
        width: '120px',
        marginRight: '8px',
        backgroundColor: '#ffffff',
    };

    const handleConfirmNavigation = () => {
        setShowUnsavedChangesDialog(false);
        setHasUnsavedChanges(false);
        blocker?.proceed();
    };

    const handleCancelNavigation = () => {
        setShowUnsavedChangesDialog(false);
        blocker?.reset();
    };

    return (

        <Box maxWidth="800px">
            <Box mb={4}>
                <h5>Who should attend?</h5>
                <p>
                    List the founder's email and the email addresses of everyone from your team who should receive an invite.
                </p>
                <Autocomplete
                    multiple
                    freeSolo
                    options={[]}
                    value={attendeeEmails}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        setAttendeeEmails(newValue);
                        setHasUnsavedChanges(true);
                    }}
                    onKeyDown={(event) => {
                        if (['Enter', ',', ' '].includes(event.key)) {
                            event.preventDefault();
                            if (inputValue.trim() && isValidEmail(inputValue.trim())) {
                                handleAddEmails([inputValue]);
                                setInputValue('');
                            }
                        } else if (event.key === 'Tab' && inputValue.trim()) {
                            // Don't prevent default for Tab to allow normal navigation
                            if (isValidEmail(inputValue.trim())) {
                                handleAddEmails([inputValue]);
                            }
                            // We don't clear the input here to allow the browser to move focus as normal
                        }
                    }}
                    onBlur={() => {
                        if (inputValue.trim() && isValidEmail(inputValue.trim())) {
                            handleAddEmails([inputValue]);
                            setInputValue('');
                        }
                    }}
                    onPaste={(event) => {
                        event.preventDefault();
                        const pastedText = event.clipboardData.getData('text');
                        const emails = pastedText.split(/[\s,]+/).filter(isValidEmail);
                        handleAddEmails(emails);
                        setInputValue('');
                    }}
                    renderTags={(value: string[], getTagProps) =>
                        value.map((option: string, index: number) => (
                            <Chip
                                variant="filled"
                                label={option}
                                {...getTagProps({ index })}
                                onDelete={() => handleDeleteEmail(option)}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Type or paste emails and press Enter, comma, or space"
                            fullWidth
                        />
                    )}
                    clearIcon={null}
                    forcePopupIcon={false}
                />
            </Box>
            <Box mb={4}>
                <h5>What calendars should we check for conflicts?</h5>
                <p>
                    We will check free times on these calendars to ensure there aren't any conflicts when scheduling a demo.<br/>
                    Need help? <span className="link bold" onClick={() => setShowInstructionsDialog(true)}>Here are instructions</span> on where to find these URLs in Google Calendar and Outlook.
                </p>
                {calendars.map((calendar, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={1}>

                        <Autocomplete
                            freeSolo
                            options={attendeeEmails}
                            value={calendar.name ?? ""}
                            onChange={(_, newValue) => { handleCalendarChange(index, 'name', newValue || ''); setHasUnsavedChanges(true); }}
                            onInputChange={(_, newInputValue) => {
                                handleCalendarChange(index, 'name', newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Calendar Name"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            style={{ width: '200px', marginRight: '8px' }}
                        />
                        <TextField
                            placeholder="Calendar URL"
                            variant="outlined"
                            size="small"
                            value={calendar.url ?? ""}
                            onChange={(e) => { handleCalendarChange(index, 'url', e.target.value); setHasUnsavedChanges(true); }}
                            style={{ flexGrow: 1, marginRight: '8px' }}
                        />
                        {calendars.length > 1 && (
                            <IconButton onClick={() => handleRemoveCalendar(index)} size="small">
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Box>
                ))}
                <Box display="flex" justifyContent="flex-start">
                    <Button
                        onClick={handleAddCalendar}
                        variant="text"
                        size="small"
                        style={{ marginBottom: '16px' }}
                    >
                        + Add another calendar
                    </Button>
                </Box>
            </Box>


            <Box mb={4}>
                <h5>What meeting windows would you like to offer?</h5>
                <p>
                    To maximize your connect reate, pick the days and times you and your team can reliably make.
                </p>
                <Box mb={2}>
                    <Typography variant="body2" gutterBottom>Timezone</Typography>
                    <TimezoneSelect
                        value={selectedTimezone}
                        onChange={handleTimezoneChange}
                    />
                </Box>
                {Object.entries(availabilityWindows).map(([day, { isAvailable, ranges }]) => (
                    <Box key={day} mb={0}>
                        <Box display="flex" alignItems="flex-start">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAvailable}
                                        onChange={() => handleDayToggle(day)}
                                        name={day}
                                    />
                                }
                                label={day}
                                style={{ width: '120px', marginRight: '0', marginTop: '6px' }}
                            />
                            {isAvailable && (
                                <Box display="flex" flexDirection="column" flexGrow={1}>
                                    {ranges.map((range, index) => (
                                        <Box key={`${day}-${index}`} display="flex" alignItems="center" mb={1} mt={1}>
                                            <Select
                                                variant="outlined"
                                                size="small"
                                                value={range.start}
                                                onChange={(e) => handleTimeChange(day, index, 'start', e.target.value as string)}
                                                style={{ width: '120px', marginRight: '8px' }}
                                            >
                                                {getStartTimeOptions(range.end, ranges.filter((_, i) => i !== index)).map((time) => (
                                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                                ))}
                                            </Select>
                                            -
                                            <Select
                                                variant="outlined"
                                                size="small"
                                                value={range.end}
                                                onChange={(e) => handleTimeChange(day, index, 'end', e.target.value as string)}
                                                style={{ width: '120px', marginLeft: '8px' }}
                                            >
                                                {getEndTimeOptions(range.start, ranges.filter((_, i) => i !== index)).map((time) => (
                                                    <MenuItem key={time} value={time}>{time}</MenuItem>
                                                ))}
                                            </Select>
                                            {index === 0 ? (
                                                <IconButton onClick={() => handleAddTimeRange(day)} size="small">
                                                    <AddIcon />
                                                </IconButton>
                                            ) : (
                                                <IconButton onClick={() => handleRemoveTimeRange(day, index)} size="small">
                                                    <DeleteIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            {!isAvailable && (
                                <Box display="flex" alignItems="center" m={"auto"} flexGrow={1}>
                                    <Typography variant="body2" color="textSecondary">
                                        Unavailable
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box>
                <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading || !hasUnsavedChanges}>
                    {loading ? 'Submitting...' : 'Save Changes'}
                </Button>
            </Box>
            <UnsavedChangesDialog
                open={showUnsavedChangesDialog}
                onClose={handleCancelNavigation}
                onConfirm={handleConfirmNavigation}
                onCancel={handleCancelNavigation}
            />

            <InstructionsDialog open={showInstructionsDialog} onClose={() => setShowInstructionsDialog(false)} />
            <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} message="Changes saved" onClose={() => setShowSuccessSnackbar(false)} />
        </Box>

    );
};
