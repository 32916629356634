import { useState } from "react";
import { updateProject } from "../../services/gutcheck-admin-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { FormControlLabel, MenuItem, Select, Stack, TextField, Tooltip, FormControl, FormLabel, RadioGroup, Radio, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StyledDropzone from "../../../../components/AssetUpload";
import { uploadLogo } from "../../../../core/services/gutcheck-api.service";

export const DealForm = ({ project, reloadProjectData }: any) => {

    const [dealData, setDealData] = useState<any>({
        public_title: project.public_title ?? "",
        spv_platform_url: project.spv_platform_url ?? "",
        investor_deck: project.investor_deck ?? "",
        show_company: project.show_company ?? false,
        reveal_name_on_approval: project.reveal_name_on_approval ?? false,
        survey_id: project.survey_id ?? "",
        expert_analysis_url: project.expert_analysis_url ?? "",
        spv_stage: project.spv_stage ?? "",
        logo_url: project.logo_url ?? "",
        public_description: project.public_description ?? "",
        funding_at: project.funding_at ?? "",
        deal_terms_url: project.deal_terms_url ?? "",
        deck_thumbnail_url: project.deck_thumbnail_url ?? "",
        terms_thumbnail_url: project.terms_thumbnail_url ?? "",
        evaluation_delay_hours: project.evaluation_delay_hours ?? 48,
        spv_platform_name: project.spv_platform_name ?? "",
        founder_name: project.founder_name ?? "",
        founder_title: project.founder_title ?? "",
        founder_link: project.founder_link ?? "",
        company_domain: project.company?.domain ?? "",
    });

    const initQueuedFiles: File[] = [];
    const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);
    const [deckQueuedFiles, setDeckQueuedFiles] = useState(initQueuedFiles);
    const [termsQueuedFiles, setTermsQueuedFiles] = useState(initQueuedFiles);

    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    const handleChange = (e: any) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setDealData({ ...dealData, [e.target.name]: value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        const accessToken = await getAccessTokenSilently();

        let logoUrl = dealData.logo_url;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadLogo(queuedFiles[0], project.tag, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            logoUrl = data.response.url;
        }

        let deckThumbnailUrl = dealData.deck_thumbnail_url;
        if (deckQueuedFiles.length > 0) {
            const { data, error } = await uploadLogo(deckQueuedFiles[0], project.tag, deckQueuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            deckThumbnailUrl = data.response.url;
        }

        let termsThumbnailUrl = dealData.terms_thumbnail_url;
        if (termsQueuedFiles.length > 0) {
            const { data, error } = await uploadLogo(termsQueuedFiles[0], project.tag, termsQueuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            termsThumbnailUrl = data.response.url;
        }

        const updatedDealData = dealData;
        updatedDealData.logo_url = logoUrl;
        updatedDealData.deck_thumbnail_url = deckThumbnailUrl;
        updatedDealData.terms_thumbnail_url = termsThumbnailUrl;

        const { data, error } = await updateProject(project.id, updatedDealData, accessToken);

        if (error) {
            console.error(error);
        } else {
            reloadProjectData();
            navigate(`/admin/project/${project.id}`);
            return;
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2} width="500px" className="admin-section">
                <p className="admin-section-header">Branding</p>
                <Tooltip title="The public title is the name of the company that will be displayed to co-investors." arrow>
                    <TextField label="Public Title" type="text" name="public_title" value={dealData.public_title} onChange={handleChange} />
                </Tooltip>
                <TextField multiline rows={2} label="Short Description" type="textarea" name="public_description" value={dealData.public_description} onChange={handleChange} />
                <TextField label="Founder Name" type="text" name="founder_name" value={dealData.founder_name} onChange={handleChange} />
                <TextField label="Founder Title" type="text" name="founder_title" value={dealData.founder_title} onChange={handleChange} />
                <TextField label="Founder Link" type="text" name="founder_link" value={dealData.founder_link} onChange={handleChange} />
                <div className="edit-page-form-field textfield-wrapper">
                    <label>Company Logo</label>
                    <div className="logo-uploader">
                        <StyledDropzone fileTypes={{ 'image/*': [] }} queuedFiles={[]} setQueuedFiles={setQueuedFiles} initialFile={dealData.logo_url ?? ""} />
                        <p className="branding-guidelines">Logos should be in PNG or SVG format with a transparent background.</p>
                    </div>
                </div>
                <TextField label="Company Domain" type="text" name="company_domain" value={dealData.company_domain} onChange={handleChange}
                    InputProps={{
                        startAdornment: <Box sx={{color: "#999999"}}>https://</Box>
                    }}
                />

            </Stack>
            <Stack spacing={2} width="500px" className="admin-section">
                <p className="admin-section-header">Company Reveal</p>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="company-reveal"
                        name="company-reveal"
                        value={dealData.show_company ? "always" : (dealData.reveal_name_on_approval ? "after_approval" : "after_greenlight")}
                        onChange={(e) => {
                            const value = e.target.value;
                            setDealData((prevData: any) => ({
                                ...prevData,
                                show_company: value === "always",
                                reveal_name_on_approval: value === "after_approval"
                            }));
                        }}
                    >
                        <FormControlLabel value="always" control={<Radio />} label="Always show company" />
                        <FormControlLabel value="after_approval" control={<Radio />} label="Reveal before SPV is greenlit, when Viewpoint is approved" />
                        <FormControlLabel value="after_greenlight" control={<Radio />} label="Wait until SPV is greenlit" />
                    </RadioGroup>
                </FormControl>

            </Stack>
            <Stack spacing={2} width="500px" className="admin-section">

                <p className="admin-section-header">Investment Details</p>
                <TextField label="SPV Platform Name" type="text" name="spv_platform_name" value={dealData.spv_platform_name} onChange={handleChange} />
                <TextField label="SPV URL" type="text" name="spv_platform_url" value={dealData.spv_platform_url} onChange={handleChange} />

                <Tooltip title="The SPV Status is the current status of the SPV." arrow>
                    <TextField label="SPV Status" select name="spv_stage" value={dealData.spv_stage} onChange={handleChange} >
                        <MenuItem value="pending">Not Yet Approved</MenuItem>
                        <MenuItem value="approved">Greenlit (but Not Open)</MenuItem>
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="closed_not_greenlit">Closed - Not Greenlit</MenuItem>
                        <MenuItem value="closed_canceled">Closed - Canceled</MenuItem>
                        <MenuItem value="closed_funded">Closed - Funded</MenuItem>
                    </TextField>
                </Tooltip>

                <TextField
                    id="funding_at"
                    type="date"
                    name="funding_at"
                    label="Funding Date"
                    value={dealData.funding_at ? (new Date(dealData.funding_at)).toISOString().split('T')[0] : ""}
                    onChange={handleChange}
                />

                <TextField label="Evaluation Delay (Hours)" type="number" name="evaluation_delay_hours" value={dealData.evaluation_delay_hours} onChange={handleChange} />
            </Stack>
            <Stack spacing={2} width="500px" className="admin-section">
                <p className="admin-section-header">Alchemer Integration</p>

                <TextField label="Alchemer Survey ID" type="text" name="survey_id" value={dealData.survey_id} onChange={handleChange} />
                <TextField label="Expert Analysis URL" type="text" name="expert_analysis_url" value={dealData.expert_analysis_url} onChange={handleChange} />
            </Stack>

            <Stack spacing={2} width="500px" className="admin-section">
                <button type="submit">Submit</button>
            </Stack>
        </form>
    );

}


