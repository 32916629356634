import { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Select from 'react-select'
import { getOrganization, getProjects, updateOrganization } from '../services/gutcheck-admin-api.service';
import StyledDropzone from "../../../components/AssetUpload";
import { uploadLogo } from "../../../core/services/gutcheck-api.service";
import { FormGroup } from "@mui/material";
import { FormControlLabel, Switch, TextField } from "@mui/material";

type TAdminOrganization = {
    name: string,
    tag: string,
    logo_url: string,
    use_branding: boolean,
    website: string,
    description: string,
    email_domain: string,
    whitelist_enabled: boolean
}

const initState: TAdminOrganization = {
    name: '',
    tag: '',
    logo_url: '',
    use_branding: false,
    website: '',
    description: '',
    email_domain: '',
    whitelist_enabled: false
}

export const AdminOrganizationEdit = () => {
    const [form, setForm] = useState(initState);

    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();

    const initQueuedFiles: File[] = [];
    const [queuedFiles, setQueuedFiles] = useState(initQueuedFiles);

    const { organizationId } = useParams();

    useEffect(() => {

        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const [orgData] = await Promise.all([
                await getOrganization(organizationId ?? "-1", accessToken),
            ])

            if (orgData) {
                setForm({
                    name: orgData.data.organization.name,
                    tag: orgData.data.organization.tag,
                    logo_url: orgData.data.organization.logo_url,
                    use_branding: orgData.data.organization.logo_url !== "",
                    website: orgData.data.organization.website,
                    description: orgData.data.organization.description,
                    email_domain: orgData.data.organization.email_domain,
                    whitelist_enabled: orgData.data.organization.whitelist_enabled
                });
            }
        };

        fetchData();

        return () => {
        }
    }, []);


    const handleChange = (event: any) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    };

    const handleTagChange = (event: any) => {
        setForm({
            ...form,
            tag: event.target.value,
        });
    };

    const handleBrandSwitch = () => {
        if (form.use_branding) {
            setForm({
                ...form,
                logo_url: '',
                use_branding: false
            });
        } else {
            setForm({
                ...form,
                use_branding: true
            });
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const accessToken = await getAccessTokenSilently();

        let logoUrl = form.logo_url;
        if (queuedFiles.length > 0) {
            const { data, error } = await uploadLogo(queuedFiles[0], form.tag, queuedFiles[0].name.replace(/[^A-Za-z0-9\.]/g, ""), accessToken);
            logoUrl = data.response.url;
        }

        const organization = {
            name: form.name,
            //tag: form.tag,
            website: form.website,
            description: form.description,
            logo_url: logoUrl,
            email_domain: form.email_domain,
            whitelist_enabled: form.whitelist_enabled
        }
        await updateOrganization(organizationId ?? "-1", organization, accessToken);
        return navigate('/admin/organizations');
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="edit-page-form">
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="name">Name</label>
                    <input
                        id="name"
                        type="text"
                        value={form.name}
                        onChange={handleChange}
                    />
                </div>
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="name">Tag</label>
                    <input
                        id="tag"
                        type="text"
                        value={form.tag}
                        onChange={handleTagChange}
                        disabled={true}
                    />
                </div>
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="website">Website</label>
                    <input
                        id="website"
                        type="text"
                        value={form.website}
                        onChange={handleChange}
                    />
                </div>
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="description">Description</label>
                    <textarea
                        id="description"
                        value={form.description}
                        cols={30}
                        rows={5}
                        onChange={handleChange}
                    />
                </div>
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="name">Branding</label>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={form.use_branding} onChange={handleBrandSwitch} />} label="Use Org Branding" />
                    </FormGroup>
                    { form.use_branding && 
                        <div className="logo-uploader">
                            <StyledDropzone fileTypes={{'image/*': []}} queuedFiles={queuedFiles} setQueuedFiles={setQueuedFiles} initialFile={form.logo_url ?? ""}/>
                        </div>
                    }
                </div>
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="whitelist_enabled">Auto-approval</label>
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={form.whitelist_enabled} onChange={() => setForm({...form, whitelist_enabled: !form.whitelist_enabled})} />} label="Allow access to anyone at the following domains:" />
                    </FormGroup>
                </div>
                <div className="edit-page-form-field textfield-wrapper">
                    <label htmlFor="email_domain">Email Domains (1 per line)</label>
                    <textarea
                    rows={4}
                        id="email_domain"
                        value={form.email_domain}
                        onChange={handleChange}
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
        </Fragment>
    );

}

export default AdminOrganizationEdit;