import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getScheduler, updateScheduler, updateProject } from '../../services/gutcheck-admin-api.service';
import { useAuth0 } from '@auth0/auth0-react'
//import { Scheduler, AvailabilityWindow } from '../types';
import {
    Box,
    Typography,
    TextField,
    Switch,
    Button,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Alert,
    Paper,
    Snackbar,
    Grid,
    Card,
    CardContent,
    Divider,
    FormControlLabel
} from '@mui/material';

const dayOfWeekMap: { [key: number]: string } = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat'
};

export const SchedulerForm: React.FC<{ project: any }> = ({ project }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { projectId } = useParams<{ projectId: string }>();
    const [scheduler, setScheduler] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [internalAttendee, setInternalAttendee] = useState('');
    const [isEnabled, setIsEnabled] = useState(project.prospecting_enabled);

    useEffect(() => {
        const fetchScheduler = async () => {
            try {
                setLoading(true);
                const accessToken = await getAccessTokenSilently();
                const { data, error } = await getScheduler(project.id, "prospect", accessToken);
                if (data && data.scheduler) {
                    setScheduler(data.scheduler);
                }
            } catch (err) {
                setError('Failed to fetch scheduler');
            } finally {
                setLoading(false);
            }
        };

        fetchScheduler();
    }, [projectId]);

    const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScheduler((prev: any) => {
            const newScheduler = prev ? { ...prev, scheduling_url: e.target.value } : null;
            setIsFormChanged(true);
            return newScheduler;
        });
    };

    const handleToggleActive = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScheduler((prev: any) => {
            const newScheduler = prev ? { ...prev, is_active: e.target.checked } : null;
            setIsFormChanged(true);
            return newScheduler;
        });
    };

    const handleToggleEnabled = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await updateProject(project.id, { prospecting_enabled: !isEnabled }, accessToken);
            if (data) {
                setIsEnabled(!isEnabled);
            }
        } catch (err) {
            setError('Failed to update project');
        }
    };

    const handleInternalAttendeeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setScheduler((prev: any) => {
            const newScheduler = prev ? { ...prev, internal_attendee: e.target.value } : null;
            setIsFormChanged(true);
            return newScheduler;
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (scheduler && isFormChanged) {
            try {
                const accessToken = await getAccessTokenSilently();
                await updateScheduler(project.id, "prospect", {
                    scheduling_url: scheduler.scheduling_url,
                    is_active: scheduler.is_active,
                    internal_attendee: scheduler.internal_attendee
                }, accessToken);
                setSuccessMessage('Scheduler updated successfully');
                setIsFormChanged(false);
            } catch (err) {
                setError('Failed to update scheduler');
            }
        }
    };

    const handleCloseSnackbar = useCallback(() => {
        setSuccessMessage('');
    }, []);

    const groupedAvailabilityWindows = () => {
        const grouped: { [key: string]: any[] } = {};
        for (let i = 0; i < 7; i++) {
            grouped[dayOfWeekMap[i]] = [];
        }

        scheduler?.availability_windows.forEach((window: any) => {
            const day = dayOfWeekMap[window.day_of_week];
            grouped[day].push(window);
        });

        return grouped;
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Box sx={{ maxWidth: 1200, mt: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h5>
                    Customer Engagement / Prospecting
                </h5>
                <FormControlLabel
                    control={<Switch checked={isEnabled} onChange={handleToggleEnabled} />}
                    label="Enable Prospecting"
                />
            </Box>
            {isEnabled && scheduler && (
            <Box sx={{ display: 'flex', gap: 2 }}>
                {/* Left Column */}
                <Box sx={{ flex: 1 }}>

                    <Typography variant="subtitle1">Calendars</Typography>
                    <List dense>
                        {scheduler.calendars?.map((calendar: any) => (
                            <ListItem key={calendar.id}>
                                <ListItemText
                                    primary={calendar.name}
                                    secondary={calendar.url}
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    secondaryTypographyProps={{ variant: 'caption' }}
                                />
                            </ListItem>
                        ))}
                    </List>


                    <Typography variant="subtitle1">
                        Availability Windows
                        {scheduler.timezone && (
                            <Typography variant="caption" component="span" sx={{ ml: 1 }}>
                                (Timezone: {scheduler.timezone})
                            </Typography>
                        )}
                    </Typography>
                    <List dense>
                        {Object.entries(groupedAvailabilityWindows()).map(([day, windows]) => (
                            <ListItem key={day}>
                                <ListItemText
                                    primary={day}
                                    secondary={
                                        windows.length > 0
                                            ? windows.map((window: any) =>
                                                `${window.start_time} - ${window.end_time}`
                                            ).join(', ')
                                            : 'Unavailable'
                                    }
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    secondaryTypographyProps={{ variant: 'caption' }}
                                />
                            </ListItem>
                        ))}
                    </List>

                    <Typography variant="subtitle1">Attendees</Typography>
                    <List dense>
                        {scheduler.attendees?.map((attendee: any) => (
                            <ListItem key={attendee.id}>
                                <ListItemText
                                    primary={attendee.name}
                                    secondary={attendee.email}
                                    primaryTypographyProps={{ variant: 'body2' }}
                                    secondaryTypographyProps={{ variant: 'caption' }}
                                />
                            </ListItem>
                        ))}
                    </List>

                </Box>

                {/* Right Column */}
                <Box sx={{ flex: 1 }}>
                    <Card elevation={2}>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    fullWidth
                                    label="Calendly URL"
                                    value={scheduler.scheduling_url}
                                    onChange={handleUrlChange}
                                    margin="normal"
                                />
                                <TextField
                                    fullWidth
                                    label="Internal Attendee Email"
                                    value={scheduler.internal_attendee}
                                    onChange={handleInternalAttendeeChange}
                                    margin="normal"
                                />
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                                    <Typography variant="body1">Active:</Typography>
                                    <Switch
                                        checked={scheduler.is_active}
                                        onChange={handleToggleActive}
                                        color="primary"
                                    />
                                </Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!isFormChanged}
                                    fullWidth
                                >
                                    Save Changes
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            )}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={successMessage}
            />
        </Box>
    );
};

export default SchedulerForm;
