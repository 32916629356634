import { updateSurveyReponse } from "../../../core/services/gutcheck-api.service";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams, useSearchParams } from "react-router-dom";
import useAnalytics from "../../../hooks/useAnalytics";

export const EvaluationExit = () => {

    const [surveyResponse, setSurveyResponse] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [searchParams] = useSearchParams();
    const surveyResponseId = searchParams.get('blid');
    const { trackSurveyResponseEvent } = useAnalytics();

    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;

        const exitSurvey = async () => {
            if (!isMounted) return;

            if (surveyResponseId) {
                const { data, error } = await updateSurveyReponse(surveyResponseId, { status: searchParams.get('status'), name: searchParams.get('contact[0]') ?? "", email_address: searchParams.get('contact[1]') ?? "" });

                if (data) {
                    setSurveyResponse(data.surveyResponse);
                    setLoading(false);
                    if (data.surveyResponse.status === 'complete') {
                        const trackingData = {
                            survey_link_partner: data.surveyResponse.survey_link.partner_name ?? '',
                            project: data.surveyResponse.survey_link.project.public_title ?? '',
                            organization: data.surveyResponse.survey_link.project.organization.name ?? '',
                            survey_link_name: data.surveyResponse.survey_link.name ?? '',
                            survey_link_id: data.surveyResponse.survey_link.public_id ?? '',
                            survey_link: window.location.href ?? ''
                        }
                        trackSurveyResponseEvent(
                            data.surveyResponse,
                            'Lifecycle - Activated - Complete',
                            trackingData
                        );
                        trackSurveyResponseEvent(data.surveyResponse, `Completed expert evaluation`);
                    } else if (data.surveyResponse.status === 'screened') {
                        trackSurveyResponseEvent(data.surveyResponse, `Screened out of expert evaluation`);
                    } else if (data.surveyResponse.status === 'over_quota') {
                        trackSurveyResponseEvent(data.surveyResponse, `Over quota for expert evaluation`);
                    }
                }
            }
        };

        if (surveyResponseId) {
            exitSurvey();
        }

        return () => { isMounted = false };
    }, []);

    if (loading || !surveyResponse) return (<></>);

    if (surveyResponse?.survey_link?.passthrough) {

        switch (surveyResponse.status) {
            case 'complete': {
                const paramsPrefix = /\?/.test(surveyResponse.survey_link.complete_redirect_url) ? '&' : '?';
                const redirectUrl = `${surveyResponse.survey_link.complete_redirect_url}${paramsPrefix}${surveyResponse.params}`;
                trackSurveyResponseEvent(
                    surveyResponse,
                    'Redirected to complete',
                    { passthrough: true},
                    () => { window.location.replace(redirectUrl)}
                );
                return <></>;
            }
            case 'screened': {
                const paramsPrefix = /\?/.test(surveyResponse.survey_link.screen_redirect_url) ? '&' : '?';
                const redirectUrl = `${surveyResponse.survey_link.screen_redirect_url}${paramsPrefix}${surveyResponse.params}`;
                trackSurveyResponseEvent(
                    surveyResponse,
                    'Redirected to screened',
                    { passthrough: true},
                    () => { window.location.replace(redirectUrl)}
                );
                return <></>;
            }
            case 'over_quota': {
                const paramsPrefix = /\?/.test(surveyResponse.survey_link.quota_redirect_url) ? '&' : '?';
                const redirectUrl = `${surveyResponse.survey_link.quota_redirect_url}${paramsPrefix}${surveyResponse.params}`;
                trackSurveyResponseEvent(
                    surveyResponse,
                    'Redirected to over_quota',
                    { passthrough: true},
                    () => { window.location.replace(redirectUrl)}
                );
                return <></>;
            }
            default:
                return;
        }
    } else {
        navigate(`/e/${surveyResponse?.survey_link?.public_id}/expert/${surveyResponseId}`);
        return
    }
}