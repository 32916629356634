import { Box, Dialog, styled, Table, TableBody, TableCell, TableHead, TableProps, TableRow, Typography } from "@mui/material";
import { DealExecutive } from "./DealExecutive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DealCompany } from "./DealCompany";
import { useState } from "react";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { sendFeatureRequest } from "../../../core/services/gutcheck-api.service";
import { useDealContext } from "../context";
import { AngelInvitation } from "./angels/AngelInvitation";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';


const AngelTable = styled(Table)<TableProps>(({ theme }) => ({
    borderCollapse: "separate",
    borderSpacing: "0 0.5rem",
    marginBottom: "60px",
    "& th": {
        fontWeight: 700,
        fontSize: "1rem",
        borderBottom: "1px solid #000000",
        "&.td-sm": {
            width: "10%"
        },
        "&.td-md": {
            width: "20%"
        },
        "&.td-lg": {
            width: "30%"
        }
    },
    "& td": {
        fontSize: "1rem",
        "&.action": {
            fontSize: "1.5rem",
            cursor: "pointer",
        }
    },
    "& .td-center": {
        textAlign: "center",
    }
}));

const AngelHeading = styled("h5")(({ theme }) => ({
    fontSize: "1.5rem",
    margin: "1rem 0",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
    "& span": {
    }
}));

const AngelStaging = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "#f8f9fa",
    borderTop: "1px solid #000000",
    padding: "40px",
    marginBottom: "60px",
    "& p": {
        padding: "30px",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        color: "#000000",
        border: "1px solid #c9c9c9"
    }
}));

export const DealAngelsList = ({project, viewpoints, user, angelStatus, openViewpointId}: any) => {

    dayjs.extend(relativeTime);

    const [angelDialogOpen, setAngelDialogOpen] = useState(false);
    const [guestDialogOpen, setGuestDialogOpen] = useState(false);
    const [ notAvailableDialogOpen, setNotAvailableDialogOpen ] = useState(false);
    const [ completedDialogOpen, setCompletedDialogOpen ] = useState(false);
    const [ requested, setRequested ] = useState(false);
    const [ activeViewpoint, setActiveViewpoint ] = useState<any>();

    const { getAccessTokenSilently } = useAuth0();
    const { dealContext } = useDealContext();

    const [ angelsList, setAngelsList ] = useState<any[]>(viewpoints.filter((viewpoint: any) => {
        switch (angelStatus) {
            case "Funded":
                return viewpoint?.survey_response?.funded;
            case "Reserved":
                return viewpoint?.survey_response?.reserved_spot && !viewpoint?.survey_response?.funded;
            case "Soft-Circled":
                return viewpoint?.interest_investor && !viewpoint?.survey_response?.funded && !viewpoint?.survey_response?.reserved_spot;
            default:
                return false;
        }
    }));


    const updateViewpointInAngelsList = (viewpoint: any) => {
        const index = angelsList.findIndex((angel: any) => angel.id === viewpoint.id);
        if (index > -1) {
            const newAngelsList = [...angelsList];
            newAngelsList[index] = viewpoint;
            setAngelsList(newAngelsList);
        }
    }

    const totalAmount = angelsList.reduce((acc: number, viewpoint: any) => {
        switch (angelStatus) {
            case "Funded":
                return acc + viewpoint?.survey_response?.funded_amount ?? 0;
            case "Reserved":
                return acc + viewpoint?.survey_response?.reserved_amount ?? 0;
            case "Soft-Circled":
                return acc + ((viewpoint?.investment_amount_high ?? 0) + (viewpoint?.investment_amount_low ?? 0))/2;
            default:
                return acc;
        }
    }, 0);

    const handleFeatureRequest = async () => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await sendFeatureRequest(project.tag, "ANGELS", accessToken);
        if (data) {
            setRequested(true);
        }
    }

    const handleFeatureClick = (viewpoint: any) => {
        if (dealContext.role === "admin" || dealContext.role === "contributor") {
            if (["approved", "open"].includes(project.spv_stage)) {
                setActiveViewpoint(viewpoint);
                setAngelDialogOpen(true);
            } else if (["closed_not_greenlit", "closed_canceled", "closed_funded"].includes(project.spv_stage)) {
                setCompletedDialogOpen(true);
                return;
            } else {
                setNotAvailableDialogOpen(true);
                return;
            }
        } else {
            setGuestDialogOpen(true);
        }
    }

    if(!dealContext.fetched) {
        return <Box>Loading...</Box>
    }

    if (!angelsList?.length) {
        return (
            <Box>
                <AngelHeading>{angelStatus} <span>${totalAmount.toLocaleString()}</span></AngelHeading>
                <AngelStaging>
                    <p>Invite key executives who you would like to participate in your round.</p>
                </AngelStaging>
            </Box>
        );
    }

    const date = dayjs().add(7, 'days').format('MM/DD/YYYY');


    return (
        <Box>
            <AngelHeading>{angelStatus} <span>${Math.round(totalAmount).toLocaleString()}</span></AngelHeading>
            <AngelTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="td-md">Executive</TableCell>
                        <TableCell className="td-md">Title</TableCell>
                        <TableCell className="td-md td-center">Company</TableCell>
                        <TableCell className="td-md td-center">Amount</TableCell>
                        <TableCell className="td-sm td-center">Invite</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {angelsList.map((viewpoint: any) => (
                        <TableRow key={viewpoint.id}>
                            <TableCell className="td-md">
                                <DealExecutive project={project} viewpoint={viewpoint} style="angel-list" user={user} initialOpen={openViewpointId === viewpoint.uuid} />
                            </TableCell>
                            <TableCell className="">{viewpoint.title}</TableCell>
                            <TableCell className=""><DealCompany viewpoint={viewpoint} /></TableCell>
                            <TableCell className="td-center">
                                {
                                    angelStatus === "Funded" ? `$${viewpoint.survey_response?.funded_amount.toLocaleString()}` :
                                    angelStatus === "Reserved" ? `$${viewpoint.survey_response?.reserved_amount.toLocaleString()}` :
                                    `$${viewpoint.investment_amount_low.toLocaleString()} - $${viewpoint?.investment_amount_high.toLocaleString()}`
                                }
                            </TableCell>
                            <TableCell className="td-center action" onClick={() => handleFeatureClick(viewpoint)}><FontAwesomeIcon icon={faEnvelope}/>
                                { viewpoint.survey_response?.last_angel_message_at &&
                                    <div><Typography variant="body2">Last Sent:<br/>{ dayjs(viewpoint.survey_response.last_angel_message_at).fromNow()}</Typography></div>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </AngelTable>
            <Dialog open={guestDialogOpen} onClose={() => setGuestDialogOpen(false)}>
                <Box>
                    <h5>Setup Your Syndicate</h5>
                    <p>This feature is not available to guests.</p>
                </Box>
            </Dialog>
            <Dialog open={completedDialogOpen} onClose={() => setCompletedDialogOpen(false)}>
                <Box>
                    <h5>Setup Your Syndicate</h5>
                    <p>This syndicate is closed to new strategic investors.</p>
                </Box>
            </Dialog>
            <Dialog open={notAvailableDialogOpen} onClose={() => setNotAvailableDialogOpen(false)}>
                <Box>
                    <h5>Setup Your Syndicate</h5>
                    <p>Add these buyers as strategic investors in your round, with a single entry in your cap table.</p>
                    { requested ? <><button className="requested disabled"><FontAwesomeIcon icon="check"/>&nbsp;&nbsp;Learn More</button> <span>&nbsp;&nbsp;Thanks. We'll be in touch shortly</span></> : <button onClick={handleFeatureRequest}>Learn More</button> }
                </Box>
            </Dialog>

            { angelDialogOpen && <AngelInvitation project={project} viewpoint={activeViewpoint} user={user} updateViewpoint={updateViewpointInAngelsList} open={angelDialogOpen} setOpen={setAngelDialogOpen} /> }

        </Box>
    );





    }