import { Box, Button, Modal, TextField, Typography, Alert, Stack } from "@mui/material";
import { DealExecutive, ExecutiveViewpointLink } from "../DealExecutive";
import { useState } from "react";
import { ModalBox } from "../../../../components/ModalBox";
import { styled } from "@mui/system";
import { useAuth0 } from "@auth0/auth0-react";
import ClientProvider from "../../../../modules/ClientProvider";
import logo from "../../../../assets/images/brightloop-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import useAnalytics from "../../../../hooks/useAnalytics";


const PreviewBox = styled(Box)(({ theme }) => ({
    width: "100%",
    padding: "1rem 0",
    backgroundColor: "#ffffff",
    border: "1px solid #c9c9c9",
    borderRadius: "5px",
    overflowY: "auto",
    maxHeight: "300px",
    fontFamily: "Arial, sans-serif",
    "& .logo-bar": {
        backgroundColor: "#f8f9fa",
        borderTop: "1px solid #c9c9c9",
        borderBottom: "1px solid #c9c9c9",
        padding: "15px 0",
        marginBottom: "30px",
        "& .logo-wrapper": {
            maxWidth: "600px",
            margin: "0 auto",
        },
        "& img": {
            maxHeight: "40px",
        }
    },
    "& .invitation-message": {
        maxWidth: "600px",
        margin: "0 auto",
        "& h2": {
            fontSize: "24px",
            lineHeight: "32px",
            marginBottom: "1rem"
        },
        "& blockquote": {
            fontStyle: "normal",
            borderLeft: "4px solid #c9c9c9",
            fontFamily: "Arial, sans-serif",
            fontWeight: 500,
        },
        "& .mock-buttom": {
            backgroundColor: "#1a5dbd",
            color: "#ffffff",
            padding: "10px 40px",
            borderRadius: "5px",
            fontWeight: "bold",
            marginTop: "20px",
            display: "inline-block"
        }
    }
}));


const EditView = ({ prospectForm, setProspectForm }: any) => {
    return (
        <>
            {prospectForm.viewpoint.is_stale && <Alert sx={{ border: "1px solid #ffa500", borderRadius: "5px" }} severity="warning" icon={<FontAwesomeIcon icon="circle-exclamation" size="sm" color="#ffa500" />}>This prospect may no longer be at {prospectForm.viewpoint.company?.name ?? "their company"}. Please review their LinkedIn profile and viewpoint before sending a message.</Alert>}

            <p>Please review {prospectForm.viewpoint.name.split(" ")[0]}'s
                {prospectForm.viewpoint.linkedin && <><Link to={prospectForm.viewpoint.linkedin} target="_blank" className="link"> LinkedIn profile</Link> and </>}
                <> <ExecutiveViewpointLink project={prospectForm.project} viewpoint={prospectForm.viewpoint} text="viewpoint" />, confirm they are still at {prospectForm.viewpoint.company?.name ?? "their company"}, then write a personal note.</>
                <> {prospectForm.viewpoint.name.split(" ")[0]} will be asked to pick a time that works with your schedule.</>
                <> The more personal the note, the more likely {prospectForm.viewpoint.name.split(" ")[0]} will schedule a time.</>
            </p>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <DealExecutive project={prospectForm.project} viewpoint={prospectForm.viewpoint} style="face-grid" />
                <Stack spacing={2} width="100%">
                    <TextField
                        id="subject"
                        label="Email Subject"
                        fullWidth
                        value={prospectForm.subject}
                        onChange={(e) => setProspectForm({ ...prospectForm, subject: e.target.value })}
                    />
                    <TextField
                        id="headline"
                        label="Message Headline"
                        fullWidth
                        value={prospectForm.headline}
                        onChange={(e) => setProspectForm({ ...prospectForm, headline: e.target.value })}
                    />
                    <TextField
                        id="outlined-multiline-static"
                        label="Personal Note"
                        multiline
                        rows={7}
                        fullWidth
                        value={prospectForm.message}
                        onChange={(e) => setProspectForm({ ...prospectForm, message: e.target.value })}
                    />
                </Stack>
            </Box>
        </>

    )
}

const PreviewView = ({ prospectForm, setProspectForm }: any) => {
    return (
        <>
            <p>Preview your personal note to {prospectForm.viewpoint.name.split(" ")[0]} before sending.</p>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <DealExecutive project={prospectForm.project} viewpoint={prospectForm.viewpoint} style="face-grid" />
                <Box sx={{ width: "100%" }}>
                    <p><strong>Subject:</strong> {prospectForm.subject}</p>
                    <PreviewBox>
                        <Box className="logo-bar">
                            <Box className="logo-wrapper">
                                {prospectForm.project.organization.logo_url && prospectForm.project.organization.logo_url !== "" ?
                                    <img src={prospectForm.project.organization.logo_url} alt={prospectForm.project.organization.name} /> :
                                    <Typography sx={{ fontWeight: 700, fontSize: "1.5rem" }} />
                                }
                            </Box>
                        </Box>
                        <Box className="invitation-message">
                            <h2>{prospectForm.headline}</h2>

                            {prospectForm.message.split("\n").map((line: string, index: number) => (
                                <Typography variant="body1" key={index} sx={{ marginBottom: "1rem" }}>{line}</Typography>
                            ))}

                            <Box className="mock-buttom">Schedule Demo</Box>

                        </Box>

                    </PreviewBox>
                </Box>
            </Box>

        </>

    )
}

const StreamlinedView = ({ prospectForm, setProspectForm }: any) => {
    return (
        <>
            {prospectForm.viewpoint.is_stale && <Alert sx={{ border: "1px solid #ffa500", borderRadius: "5px", alignItems: "center" }} severity="warning" icon={<FontAwesomeIcon icon="circle-exclamation" size="sm" color="#ffa500" />}>This prospect may no longer be at {prospectForm.viewpoint.company?.name ?? "their company"}. Please review their LinkedIn profile and viewpoint before sending a message.</Alert>}

            <Box sx={{ display: "flex", flexDirection: "row", gap: "20px", alignItems: "flex-start" }}>
                <DealExecutive project={prospectForm.project} viewpoint={prospectForm.viewpoint} style="face-grid" />
                <Box sx={{ width: "100%", marginTop: "20px" }}>
                <p>Please review {prospectForm.viewpoint.name.split(" ")[0]}'s
                {prospectForm.viewpoint.linkedin && <><Link to={prospectForm.viewpoint.linkedin} target="_blank" className="link"> LinkedIn profile</Link> and </>}
                <> <ExecutiveViewpointLink project={prospectForm.project} viewpoint={prospectForm.viewpoint} text="viewpoint" /> and confirm they are still at {prospectForm.viewpoint.company?.name ?? "their company"}.</>
                </p>
                <p>
                    If {prospectForm.viewpoint.name.split(" ")[0]} is still interested in a demo, they will pick a time that works with your schedule.
                </p>
                </Box>
            </Box>
        </>
    )
}

const NotReadyView = ({ angelForm }: any) => {
    return (
        <>
            <p>Sorry, you can only send investment invitations to Executives when there is an open syndicate for them to join.</p>
        </>
    )
}

export const ProspectInvitation = ({ project, viewpoint, user, updateViewpoint, open, setOpen, handleEditSetup }: any) => {

    const { getAccessTokenSilently } = useAuth0();
    const { trackEvent } = useAnalytics();
    const projectClient = ClientProvider.provideProjectClient();
    

    const defaultMessage = `${viewpoint.name.split(" ")[0]},
    
Thanks for adding your viewpoint on my company, ${project.name}. ${project.organization.name} shared your feedback and I saw that you requested a demo. I'd love to find a time to give you one. Please let me know if there's a time that works and I'm looking forward to connecting live.

${user.name}
${project.name}`;

    const [prospectForm, setProspectForm] = useState<any>({
        project: project,
        viewpoint: viewpoint,
        subject: `Scheduling your demo with ${user.name.split(" ")[0]} at ${project.name}`,
        headline: `Message from ${project.organization.name} and ${user.name.split(" ")[0]} at ${project.name}`,
        message: defaultMessage,
        stage: "STREAMLINED",
        errorMessage: ""
    });

    const handleInvitationPreview = () => {
        setProspectForm({ ...prospectForm, stage: "PREVIEW" });
    }

    const handleInvitationSubmit = async () => {
        setProspectForm({ ...prospectForm, errorMessage: "", stage: "SENDING_STREAMLINED" });
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await projectClient.sendProspectMessage(project.id, viewpoint.id, prospectForm, accessToken);

        if (error) {
            setProspectForm({ ...prospectForm, errorMessage: error.message });
            return;
        }

        setProspectForm({ ...prospectForm, stage: "COMPLETE_STREAMLINED" });
        viewpoint.survey_response.last_prospect_message_at = new Date().toISOString();
        updateViewpoint(viewpoint);
    }

    const handleCancel = () => {
        setProspectForm({ ...prospectForm, stage: "EDIT" });
        setOpen(false);
    }

    if (!viewpoint) return <></>;

    return (
        <Modal className="invitation-container" open={open} onClose={() => setOpen(false)}>
            <ModalBox sx={{ width: "600px" }}>
                <h5>Schedule a Demo with {viewpoint.name ?? viewpoint.email_address}</h5>

                {prospectForm.stage === "NOT_READY" && <NotReadyView prospectForm={prospectForm} />}
                {prospectForm.stage === "EDIT" && <EditView prospectForm={prospectForm} setProspectForm={setProspectForm} />}
                {(prospectForm.stage === "PREVIEW" || prospectForm.stage === "SENDING") && <PreviewView prospectForm={prospectForm} setProspectForm={setProspectForm} />}
                {prospectForm.stage === "STREAMLINED" && <StreamlinedView prospectForm={prospectForm} setProspectForm={setProspectForm} />}
                {prospectForm.stage === "SENDING_STREAMLINED" && <StreamlinedView prospectForm={prospectForm} setProspectForm={setProspectForm} />}
                {prospectForm.stage === "COMPLETE" && <Typography>Your message has been sent to {viewpoint.name}</Typography>}
                {prospectForm.stage === "COMPLETE_STREAMLINED" && <StreamlinedView prospectForm={prospectForm} setProspectForm={setProspectForm} />}

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: "20px",
                    "& button": {
                        width: "200px"
                    }
                }}>
                    {prospectForm.errorMessage !== "" && <Box sx={{ color: "red" }}>{prospectForm.errorMessage}</Box>}

                    {(prospectForm.stage === "COMPLETE" || prospectForm.stage === "COMPLETE_STREAMLINED") &&
                        <>
                            <Typography sx={{ color: "green" }} className="success">Success! Request sent.</Typography>
                            <Button variant="outlined" size="large" color="primary" onClick={handleCancel}>Close</Button>
                        </>
                    }

                    {prospectForm.stage === "EDIT" && <Button variant="outlined" size="large" color="primary" onClick={handleCancel}>Cancel</Button>}
                    {prospectForm.stage === "EDIT" && <Button variant="contained" size="large" color="primary" className="btn active" onClick={handleInvitationPreview}>Preview</Button>}

                    {prospectForm.stage === "PREVIEW" && <Button variant="outlined" size="large" color="primary" onClick={() => setProspectForm({ ...prospectForm, stage: "EDIT" })}>Back</Button>}
                    {prospectForm.stage === "PREVIEW" && <Button variant="contained" size="large" color="primary" onClick={handleInvitationSubmit}>Send</Button>}

                    {prospectForm.stage === "STREAMLINED" && <Button variant="outlined" size="large" color="primary" onClick={handleCancel}>Cancel</Button>}
                    {prospectForm.stage === "STREAMLINED" && <Button variant="contained" size="large" color="primary" onClick={handleInvitationSubmit}>Request</Button>}

                    
                    {prospectForm.stage === "SENDING" && <Typography>Sending...</Typography>}
                    {prospectForm.stage === "SENDING_STREAMLINED" && <Typography>Requesting...</Typography>}

                </Box>
            </ModalBox>

        </Modal>
    )
}