import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useOutlet, useOutletContext, useParams } from "react-router-dom";
import { useOrganization } from "../hooks/useOrganization";
import { Table, TableHead, TableBody, TableRow, TableCell, Box, Dialog, TextField, MenuItem, Button, Snackbar } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import useGetProjects from "../hooks/useGetProjects";
import { copyProjectToOrganization } from "../services/gutcheck-admin-api.service";
import { deleteProject } from "../services/gutcheck-admin-api.service";
import { faSleigh } from "@fortawesome/free-solid-svg-icons";


const CopyProjectForm = ({ organization, open, setOpen }: any) => {

    const { getAccessTokenSilently } = useAuth0();
    const [projects, fetched] = useGetProjects();
    const [sourceProject, setSourceProject] = useState<any>(null);
    const [copied, setCopied] = useState(false);
    const [ loading, setLoading ] = useState(false);

    if (!fetched) return <></>;
    if (fetched && !projects) return <></>;

    const handleChange = (e: any) => {
        setSourceProject(e.target.value);
    }

    const handleSubmit = async () => {
        if (sourceProject && sourceProject.id) {
            setLoading(true);
            console.log(sourceProject);
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await copyProjectToOrganization(sourceProject.id, organization.id, accessToken);

            if (data) {
                console.log("Project copied");
                setCopied(true);
                setLoading(false);
            } else {
                console.error(error);
            }
        }
    }

    if (copied) {
        return (
            <Dialog open={open} onClose={() => setOpen(false)}>
                <Box>
                    <h5>Copy Project</h5>
                </Box>
                <Box>
                    <p>Project copied successfully</p>
                </Box>
            </Dialog>
        ); 
    }

    if (loading) {
        return (
            <Dialog open={open} onClose={() => setOpen(false)}>
                <Box>
                    <h5>Copy Project</h5>
                </Box>
                <Box>
                    <p>Copying project...</p>
                </Box>
            </Dialog>
        ); 
    }

    return (
        <Dialog open={open} onClose={() => { setOpen(false) }}>
            <Box>
                <h5>Copy Project</h5>
            </Box>
            <Box>
                <TextField
                    label="Project"
                    select
                    name="project"
                    id="project"
                    value={sourceProject}
                    onChange={handleChange}
                >
                    {projects.map((project: any) => (
                        <MenuItem key={project.id} value={project}>{project.id} - {project.organization.name} - {project.name}</MenuItem>
                    ))}
                </TextField>
                <Button onClick={handleSubmit}>Copy</Button>

            </Box>
        </Dialog>
    );
}


export const AdminOrganizationProjects = () => {

    const { organization } = useOutletContext<{ organization: any }>();
    const [open, setOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState<any>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [filteredProjects, setFilteredProjects] = useState<any[]>([]);

    if (!organization) return null;

    useEffect(() => {
        if (organization && organization.projects) {
            setFilteredProjects(organization.projects.filter((project: any) => !project.is_deleted));
        }
    }, [organization]);

    const handleDeleteClick = (project: any) => {
        setProjectToDelete(project);
        setDeleteConfirmOpen(true);
    };

    const handleDeleteConfirm = async () => {
        if (projectToDelete) {
            const accessToken = await getAccessTokenSilently();
            const { data, error } = await deleteProject(projectToDelete.id, accessToken);

            if (data) {
                setSnackbarOpen(true);
                // Remove the deleted project from the filtered list
                setFilteredProjects(prevProjects => prevProjects.filter(project => project.id !== projectToDelete.id));
            } else {
                console.error(error);
            }
        }
        setDeleteConfirmOpen(false);
        setProjectToDelete(null);
    };

    return (
        <>
            <Box>
                <h5>Projects</h5>
                <span className="link" onClick={() => setOpen(true)}>Copy Project</span>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Project Name</TableCell>
                        <TableCell>Project Tag</TableCell>
                        <TableCell>Project Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredProjects.map((project: any) => (
                        <TableRow key={project.id}>
                            <TableCell>{project.id}</TableCell>
                            <TableCell><Link to={`/admin/project/${project.id}`}>{project.name}</Link></TableCell>
                            <TableCell>{project.tag}</TableCell>
                            <TableCell>{project.status}</TableCell>
                            <TableCell>
                                <span className="link" onClick={() => handleDeleteClick(project)}>Delete</span>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {open && <CopyProjectForm organization={organization} open={open} setOpen={setOpen} />}
            
            <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
                <Box p={2}>
                    <h5>Confirm Delete</h5>
                    <p>Are you sure you want to delete the project "{projectToDelete?.name}"?</p>
                    <Box mt={2}>
                        <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">Cancel</Button>
                        <Button onClick={handleDeleteConfirm} color="error">Delete</Button>
                    </Box>
                </Box>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message="Project deleted successfully"
            />
        </>
    );
}
