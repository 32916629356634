import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "@mui/material";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/login/silent",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Button 
      id="qsLoginBtn"
      color="primary"
      variant="contained"
      className="btn-margin"
      onClick={handleLogin}>
      Log In
    </Button>
  );
};
