import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { getRespondentData } from "../core/services/gutcheck-api.service";
import { useDealContext } from "../features/deal";
import { useGuestAuthContext } from "../features/guest";
import ClientProvider from "../modules/ClientProvider";

const useGetRespondentHtml = (respondentId: string, project: any) => {
    const { getAccessTokenSilently } = useAuth0();
    const { dealContext } = useDealContext();
    const { token } = useGuestAuthContext();

    const [state, setState] = useState({
        respondentHtml: {
            __html: ""
        },
        fetched: false
    });

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            if (!respondentId) {
                setState({
                    ...state,
                    respondentHtml: { __html: '' }
                });
                return;
            }
            
            if(dealContext.role === "guest" && token) {
                const client = ClientProvider.provideGuestClient();
                const { data, error } = await client.getRespondentData(project.project_share_links[0].share_url, respondentId, project, token);
                if (data) {
                    setState({
                        ...state,
                        respondentHtml: { __html: data.html },
                        fetched: true
                    });
                }
                return;
            } else {

                const accessToken = await getAccessTokenSilently();

                const { data } = await getRespondentData(respondentId, project, accessToken);

                if (!isMounted) return;

                if (data) {
                    setState({
                        ...state,
                        respondentHtml: { __html: data.html },
                        fetched: true
                    });
                };
            }
        };

        if (dealContext.fetched) {
            fetchData(); 
        }

        return () => { isMounted = false };
    }, [respondentId, dealContext.fetched, token]);

    return [state.respondentHtml, state.fetched];
};

export default useGetRespondentHtml;