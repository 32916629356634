import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getSurveyResponse, initEvaluation } from "../../../core/services/gutcheck-api.service";
import { EvaluationSidebar } from "../components/EvaluationSidebar";
import { DealStatusMessage } from "../components/DealStatusMessage";
import Intercom from "@intercom/messenger-js-sdk";
import useAnalytics from "../../../hooks/useAnalytics";

export const Evaluation = ({ initialSection }: any) => {

    const { evaluationId, expertId } = useParams();
    const [searchParams] = useSearchParams();

    const [surveyResponse, setSurveyResponse] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [section, setSection] = useState<string>(initialSection || 'evaluate');
    const { trackSurveyResponseUser, trackSurveyResponseEvent } = useAnalytics();

    const navigate = useNavigate();

    const investmentStages = ['approved', 'open', 'closed_canceled', 'closed_funded'];


    const loadSurveyResponse = async () => {

        if (evaluationId) {

            const newParam = searchParams.get('new');
            const forceNew = newParam === 'true';

            if (forceNew && !expertId) {
                localStorage.removeItem('eval' + evaluationId);
                searchParams.delete('new');
            }

            const activeExpertId = expertId ?? localStorage.getItem('eval' + evaluationId);

            if (!activeExpertId) {
                const initResponse = await initEvaluation(evaluationId, searchParams);
                if (initResponse.data) {
                    const newExpertID = initResponse.data.surveyResponse.public_id;
                    const { data, error } = await getSurveyResponse(newExpertID);
                    setSurveyResponse(data.surveyResponse);
                    localStorage.setItem('eval' + evaluationId, data.surveyResponse.public_id);
                    trackSurveyResponseEvent(data.surveyResponse,
                        'Lifecycle - Acquired',
                        {
                            survey_link_partner: data.surveyResponse.survey_link.partner_name,
                            survey_link_name: data.surveyResponse.survey_link.name,
                            survey_link_id: data.surveyResponse.survey_link.public_id,
                            survey_link: window.location.href
                        }
                    );
                }
            }
            else {
                const { data, error } = await getSurveyResponse(activeExpertId);
                if (data) {
                    setSurveyResponse(data.surveyResponse);
                    localStorage.setItem('eval' + evaluationId, data.surveyResponse.public_id);
                }
            }

            setLoading(false);
        }
    };

    useEffect(() => {
        let isMounted = true;

        if (evaluationId) {
            loadSurveyResponse();
        }

        return () => { isMounted = false; };
    }, []);

    useEffect(() => {

        if (surveyResponse) {
            trackSurveyResponseUser(surveyResponse, { partner: surveyResponse?.survey_link?.partner_name });
            trackSurveyResponseEvent(surveyResponse, 'Visited survey link');
            if (process.env.REACT_APP_INTERCOM_APP_ID) {
                Intercom({
                    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
                    user_id: surveyResponse.email_address && surveyResponse.email_address !== "" ? surveyResponse.uuid : null, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                    name: surveyResponse.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                    email: surveyResponse.email_address, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
                    created_at: (new Date(surveyResponse.createdAt)).valueOf(), // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
                });
            } else {
                console.warn('Intercom is not configured');
            }
        }

        if (surveyResponse && !expertId) {
            if (surveyResponse.status !== "init") {
                navigate(`/e/${surveyResponse.survey_link.public_id}/expert/${surveyResponse.public_id}`);
                return;
            }
        }
    }, [surveyResponse]);

    if (loading || !surveyResponse) return (<></>);

    if (surveyResponse?.survey_link?.passthrough && surveyResponse?.status === 'init') {

        if (!surveyResponse.survey_link.is_active) {
            const paramsPrefix = /\?/.test(surveyResponse.survey_link.quota_redirect_url) ? '&' : '?';
            const redirectUrl = `${surveyResponse.survey_link.quota_redirect_url}${paramsPrefix}${surveyResponse.params}`;
            trackSurveyResponseEvent(
                surveyResponse,
                'Redirected to over_quota',
                { passthrough: true },
                () => { window.location.replace(redirectUrl) }
            );
            return <></>;
        }

        trackSurveyResponseEvent(
            surveyResponse,
            'Redirected to survey',
            { passthrough: true },
            () => { window.location.replace(`${surveyResponse.survey_link.survey_url}?blid=${surveyResponse.public_id}&${surveyResponse.params}`) }
        );
        return <></>;
    }

    const organization = surveyResponse.survey_link.project.organization;
    const project = surveyResponse.survey_link.project;

    const investmentUnlocked = surveyResponse.invited_to_invest && investmentStages.includes(surveyResponse.survey_link.project.spv_stage) && (Date.now() - new Date(surveyResponse.completed_at).getTime() > (project.evaluation_delay_hours ?? 48) * 60 * 60 * 1000);
    const engageUnlocked = surveyResponse.last_prospect_message_at ? true : false;

    if (loading || !surveyResponse) return (<></>);

    return (
        <div className="evaluation-expert-container">
            <aside className="page-sidebar evaluation-sidebar sidebar-light">
                <EvaluationSidebar surveyResponse={surveyResponse} investmentUnlocked={investmentUnlocked} engageUnlocked={engageUnlocked} section={section} setSection={setSection} />
            </aside>
            <main className="page-main evaluation-page sidebar">
                <div className={`gc_view`}>
                    <h3 className="evaluation-title">BrightLoop &gt; Evaluate a {surveyResponse.survey_link.project.public_title}</h3>
                    <DealStatusMessage surveyResponse={surveyResponse} />

                    <div className="flex-row project-overview">
                        <Outlet context={{ surveyResponse: surveyResponse, loading: loading, investmentUnlocked: investmentUnlocked, engageUnlocked: engageUnlocked, loadSurveyResponse: loadSurveyResponse }} />
                    </div>
                </div>
            </main>
        </div>
    );
    /*
    return (
        <Outlet context={{ surveyResponse: surveyResponse, loading: loading, investmentUnlocked: investmentUnlocked }}/>
    );
    */

}