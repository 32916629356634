import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { AddUserForm } from "../components/form-add-user";
import { getAllUsers } from "../../../core/services/gutcheck-api.service";
import { UserProfileImage } from "../../users/components/UserProfileImage";
import { DocumentTitle } from "../../../components/DocumentTitle";
// Add new MUI imports
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Box,
} from "@mui/material";

type TAdminUsers = {
    users: any | null,
    filteredUsers: any | null,
    highlightedUser: any | null,
    addUserMessage: any | null
};

const initState: TAdminUsers = {
    users: null,
    filteredUsers: null,
    highlightedUser: null,
    addUserMessage: ""
}

export const AdminUsers = () => {
    const [state, setState] = useState(initState);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchData = async () => {
            const accessToken = await getAccessTokenSilently();

            const [respUsers] = await Promise.all([
                getAllUsers(accessToken)
            ]);

            if (respUsers) {
                setState({
                    ...state,
                    users: respUsers.data.users,
                    filteredUsers: respUsers.data.users
                });
            };
        };

        fetchData();

        return () => { };
    }, []);

    if (!state.users) return <></>;

    const handleAddUserClick = async (event: any) => {
        event.target.parentElement.parentElement.classList.add("show-form");
        event.preventDefault();
        setState({
            ...state,
            addUserMessage: ""
        })
    };

    const handleCallback = async (user: any, message: any) => {
        const accessToken = await getAccessTokenSilently();
        const { data, error } = await getAllUsers(accessToken);

        if (user !== null) {
            setState({
                ...state,
                highlightedUser: user.id,
                users: data.users,
                filteredUsers: data.users,
                addUserMessage: message
            });
        } else {
            setState({
                ...state,
                addUserMessage: message
            })
        }

        document.getElementsByClassName("add-object-wrapper")[0].classList.remove("show-form");
    };

    const handleSearch = (event: any) => {
        const queryString = event.target.value;
        const filteredUsers = state.users.filter((user: any) => {
            return user.email_address.toLowerCase().indexOf(queryString.toLowerCase()) !== -1;
        });

        setState({
            ...state,
            filteredUsers: filteredUsers
        });
    };

    return (
        <>
            <DocumentTitle pageTitle="Admin - Users" />
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', mb: 3 }}>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TextField
                        label="Search"
                        size="small"
                        onChange={handleSearch}
                        sx={{ minWidth: 200 }}
                    />
                </Box>
                <Box className="add-object-wrapper">
                    <Box className="add-object-toggle">
                        <Box className="add-object-message">{state.addUserMessage}</Box>
                        <Button
                            variant="contained"
                            onClick={handleAddUserClick}
                        >
                            Add User
                        </Button>
                    </Box>
                    <Box className="add-object-form">
                        <AddUserForm parentCallback={handleCallback} />
                    </Box>
                </Box>
            </Box>

            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Auth</TableCell>
                            <TableCell>Role</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Seen</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.filteredUsers.map((user: any) => {
                            const url = `/admin/user/${user.id}/edit`;
                            return (
                                <TableRow
                                    key={user.id}
                                    sx={state.highlightedUser && user.id === state.highlightedUser ?
                                        { backgroundColor: 'action.hover' } : {}}
                                >
                                    <TableCell>
                                        <Link to={url}>
                                            {user.id}
                                        </Link>
                                    </TableCell>
                                    <TableCell><UserProfileImage user={user} size="small" /></TableCell>
                                    <TableCell>
                                        <Link to={url}>
                                            {user.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Link to={url}>
                                            {user.email_address ? user.email_address : "no email"}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{user.external_id.replace(/\|.*/, "")}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>
                                        {user.created_at ? new Date(user.created_at).toLocaleDateString('en-US',
                                            { month: 'short', day: 'numeric', year: 'numeric' }) : "---"}
                                    </TableCell>
                                    <TableCell>
                                        {user.latest_visit ? new Date(user.latest_visit).toLocaleDateString('en-US',
                                            { month: 'short', day: 'numeric', year: 'numeric' }) : "---"}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
