import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import Project from "../../../projects/types/Project";
import { useEffect, useState } from "react";
import ClientProvider from "../../../../modules/ClientProvider";
import { useAuth0 } from '@auth0/auth0-react';
import { DemoScheduler } from "./DemoScheduler";
import Loading from "../../../../components/Loading";
import User from "../../../users/types/User";
import { ProspectInvitation } from "./ProspectInvitation";
import { Link } from "react-router-dom";

export const ProspectDialog = ({ project, viewpoint, user, open, onClose, updateViewpoint }: { project: Project, viewpoint: any, user: User, open: boolean, onClose: () => void, updateViewpoint: (viewpoint: any) => void }) => {

    const { getAccessTokenSilently } = useAuth0();
    const [schedulerData, setSchedulerData] = useState<any | null>(null);
    const [ configMode, setConfigMode ] = useState<boolean>(false);

    const fetchSchedulerData = async () => {
        const accessToken = await getAccessTokenSilently();
        const projectClient = ClientProvider.provideProjectClient();
        const { data, error } = await projectClient.getDemoScheduler(project.id, "prospect", accessToken);
        if (error) {
            console.error('Error fetching demo scheduler data:', error);
            setConfigMode(true);
        } else {
            setSchedulerData(data.scheduler);
            setConfigMode(!data.scheduler || !data.scheduler?.attendees?.length || !data.scheduler?.calendars?.length);
        }
    };

    useEffect(() => {

        if (open) {
            fetchSchedulerData();
        }

        return () => {
            setSchedulerData(null);
        };
    }, [project.id, open]);

    const onSubmit = async (data: any) => {
        fetchSchedulerData();
    };

    const handleEditSetup = () => {
        
    };

    if (!schedulerData && !configMode) {
        return <></>;
    }

    if (configMode) {
        return (
            <Dialog open={open} onClose={onClose}>
                <h5>Schedule a Demo</h5>
                <p>
                    You'll need to setup your calendar before you can schedule demos.
                </p>

                        <Link className="btn btn-primary" to={`/o/${project.organization.tag}/d/${project.tag}/availability`}>Setup Calendar</Link>
            </Dialog>
            );
        }

    if (!schedulerData.is_active) {
        return (
        <Dialog open={open} onClose={onClose}>
            <h5>Schedule a Demo</h5>
            <p>
                This feature is currently being setup and will be available within one business day.
            </p>
        </Dialog>
        );
    }

    return (
        <ProspectInvitation project={project} viewpoint={viewpoint} user={user} open={open} setOpen={onClose} updateViewpoint={updateViewpoint} handleEditSetup={handleEditSetup} />
    )
}