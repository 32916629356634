import TextViewer from "../../../components/TextViewer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { EvaluationSummary } from "../components/EvaluationSummary";
import { EvaluationActivity } from "../components/EvaluationActivity";
import { getScheduler, surveyResponseScheduleEvent } from "../../../core/services/gutcheck-api.service";
import { v4 } from "uuid";
import { useGetViewpointHtml } from "../hooks";
import { DocumentTitle } from "../../../components/DocumentTitle";
import useAnalytics from "../../../hooks/useAnalytics";
import { DemoScheduler } from "../components/DemoScheduler";
import { type } from "os";
import { PopupModal, useCalendlyEventListener } from "react-calendly";

export const EvaluationEngage = () => {
    const { surveyResponse, investmentUnlocked, loading, loadSurveyResponse } = useOutletContext<any>();
    const { trackSurveyResponsePage, trackSurveyResponseEvent } = useAnalytics();
    const [isDemoSchedulerOpen, setIsDemoSchedulerOpen] = useState(false); // State for DemoScheduler
    const [scheduler, setScheduler] = useState<any | null>(null);

    useEffect(() => {
        if (surveyResponse) {
            trackSurveyResponsePage(surveyResponse, 'expert flow - engage');
        }
    }, [surveyResponse]);

    useEffect(() => {
        if (window.location.hash === "#calendar" && !surveyResponse.prospect_scheduled) {
            setIsDemoSchedulerOpen(true);
            trackSurveyResponseEvent(surveyResponse, 'Viewed calendar');
        }
    }, []);

    useEffect(() => {
        // Fetch the scheduler data
        fetchScheduler();

        return () => {
            setScheduler(null);
        }
    }, []);

    useCalendlyEventListener({
        onEventScheduled: async (e) => {
            const { data, error } = await surveyResponseScheduleEvent(surveyResponse.public_id, { prospect_scheduled: true, prospect_event_uri: e.data.payload.event.uri });
            trackSurveyResponseEvent(surveyResponse, 'Scheduled demo');
            if (data) {
                loadSurveyResponse();
            }
        }
    });

    const fetchScheduler = async () => {
        const { data, error } = await getScheduler(surveyResponse.public_id, "prospect");
        if (error) {
            console.error("Error fetching scheduler:", error);
        } else {
            setScheduler(data.scheduler);
        }
    };

    const additionalGuests = scheduler ? [...scheduler.attendees.map((attendee: any) => attendee.email), ...(scheduler.internal_attendee ? scheduler.internal_attendee.split(",") : [])] : [];

    return (
        <>
            <DocumentTitle pageTitle={`Engage with a ${surveyResponse?.survey_link?.project?.public_title}`} />
            <div className="flex-column left-column">
                <div className="evaluation-content">
                    <h5>Engage with the {surveyResponse.survey_link.project.name} team</h5>
                    <p>Review and schedule invitations from the team.</p>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Description
                                    </TableCell>
                                    <TableCell>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {surveyResponse.last_prospect_message_at}
                                    </TableCell>
                                    <TableCell>
                                        The {surveyResponse.survey_link.project.name} founder would like to invite you to a demo.
                                    </TableCell>
                                    <TableCell id="scheduler-cell">
                                        {surveyResponse.prospect_scheduled ? <span>Scheduled</span> : <button onClick={() => setIsDemoSchedulerOpen(true)}>Schedule a Demo</button>}
                                        {scheduler && (
                                            <PopupModal
                                                url={scheduler.scheduling_url}
                                                onModalClose={() => setIsDemoSchedulerOpen(false)}
                                                open={isDemoSchedulerOpen}
                                                prefill={{
                                                    name: surveyResponse.name,
                                                    email: surveyResponse.email_address,
                                                    guests: [...scheduler.attendees.map((attendee: any) => attendee.email), ...(scheduler.internal_attendee ? scheduler.internal_attendee.split(",") : [])]
                                                }}
                                                rootElement={document.getElementById("scheduler-cell") as HTMLElement}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div className="flex-column right-column">
                <EvaluationSummary surveyResponse={surveyResponse} investmentUnlocked={investmentUnlocked} />
                <EvaluationActivity surveyResponse={surveyResponse} />
            </div>
        </>
    );
}