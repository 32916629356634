import { Container, Grid } from "@mui/material";

import Loading from "../../../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useUserContext } from "../context";
import { UserProfileImage } from "../components";

export const ProfileIndex = () => {
  const { user } = useAuth0();
  const { userContext, setUserContext } = useUserContext();

  return user ? (
    <Container maxWidth="md">
      <Grid container spacing={2} alignItems="center" className="profile-header mb-5 text-center text-md-left">
        <Grid item>
          <UserProfileImage user={userContext.dbUser} size="large"/>
        </Grid>
        <Grid item xs>
          <h2>{userContext.dbUser?.name}</h2>
          <p className="lead text-muted">{user.email}</p>
        </Grid>
      </Grid>
      <Grid container>
      </Grid>
    </Container>
  ) : '';
};
