import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useDealContext } from "../../deal";
import ClientProvider from "../../../modules/ClientProvider";
import { DealSidebar, DealCollaborationModal } from "../../deal";
import { useGuestAuthContext } from "../components";
import { useUserContext } from "../../users";
import useAnalytics from "../../../hooks/useAnalytics";
import Membership from "../../users/types/Membership";

export const GuestDeal = () => {

    const { shareLinkId } = useParams();
    const { dealContext, setDealContext } = useDealContext();
    const { token, loading } = useGuestAuthContext();
    const { userContext, setUserContext } = useUserContext();
    const { trackUser } = useAnalytics();

    const guestClient = ClientProvider.provideGuestClient(); 
    
    useEffect(() => {

        const fetchGuestDealData = async () => {

            if (shareLinkId && token) {
                const { data, error } = await guestClient.getDealData(shareLinkId, token);
                if (data) {
                    setDealContext({
                        project: data.project,
                        role: data.role,
                        fetched: true
                    });
                }
            }
        }

        const fetchUserData = async () => {

            if (!token) {
              return
          }   
      
            const { data, error } = await guestClient.getUserData(token);
            if(data) {
              setUserContext({
                ...userContext,
                dbUser: data.user,
                projectMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.project }) || [],
                organizationMemberships: data.user?.memberships?.filter((membership: Membership) => { return membership.organization }) || [],
                fetched: true
              });
              trackUser(data.user);
            }
          }

        if (!loading) {
            fetchGuestDealData();
            fetchUserData();
        }

    }, [loading]);

    if (!dealContext.fetched) {
        return null;
    }

    return (
        <>
            <aside className="page-sidebar">
                <DealSidebar project={dealContext.project} role={dealContext.role}/>
            </aside>
            <main className="page-main deal-page sidebar">
                <Outlet context={{ organization: dealContext.project?.organization, project: dealContext.project, viewerRole: "guest", user: null }} />
            </main>
        </>
    );
                    

}