import { IconDefinition, library } from "@fortawesome/fontawesome-svg-core";
import { faCalendar, faFileLines, faCheck, faX, faTrash, faVolumeHigh, faEye, faEyeSlash, faPhoneVolume, faMicrophone, faUserPlus, faTriangleExclamation, faLink, faPowerOff, faClock, faUser, faVideo, faFilm, faChartArea, faList, faFilePowerpoint, faPrint, faLock, faBuilding, faListOl, faHome, faChartLine, faUsers, faMagnifyingGlassChart, faWandMagicSparkles, faGear, faLightbulb, faPenToSquare, faEnvelope, faPlus, faCircle, faRotateRight, faUnlock, faLockOpen, faComments, faMoneyBillTrendUp, faHandHoldingMedical, faHandHoldingDollar, faHandshakeAngle, faBorderAll, faFilter, faBuildingUser, faPencil, faSort, faKeyboard, faFileArrowUp, faTimes, faEllipsis, faAngleDown, faArrowUpRightFromSquare, faFlag, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faClock as faClockRegular, faEnvelope as faEnvelopeRegular } from "@fortawesome/free-regular-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faVideo);
  library.add(faFilm);
  library.add(faChartArea);
  library.add(faList);
  library.add(faListOl);
  library.add(faFilePowerpoint);
  library.add(faPrint);
  library.add(faLock);
  library.add(faBuilding);
  library.add(faHome);
  library.add(faChartLine);
  library.add(faUsers);
  library.add(faMagnifyingGlassChart);
  library.add(faWandMagicSparkles);
  library.add(faGear);
  library.add(faLightbulb);
  library.add(faPenToSquare);
  library.add(faUserPlus);
  library.add(faTriangleExclamation);
  library.add(faMicrophone);
  library.add(faPhoneVolume);
  library.add(faVolumeHigh);
  library.add(faCheck);
  library.add(faX);
  library.add(faClock);
  library.add(faEnvelope);
  library.add(faPlus);
  library.add(faCircle);
  library.add(faRotateRight);
  library.add(faUnlock);
  library.add(faMagnifyingGlassChart);
  library.add(faLockOpen);
  library.add(faComments); 
  library.add(faMoneyBillTrendUp);
  library.add(faHandHoldingMedical);
  library.add(faHandHoldingDollar);
  library.add(faHandshakeAngle);
  library.add(faBorderAll);
  library.add(faFilter);
  library.add(faBuildingUser);
  library.add(faPencil);
  library.add(faSort);
  library.add(faKeyboard);
  library.add(faLinkedin);
  library.add(faFileLines);
  library.add(faFileArrowUp);
  library.add(faTimes);
  library.add(faEllipsis);
  library.add(faTrash);
  library.add(faAngleDown);
  library.add(faCalendar);
  library.add(faFlag);
  library.add(faCircleExclamation);
  library.add(faArrowUpRightFromSquare);
  library.add(faEyeSlash);
  library.add(faEye);
  library.add(faClockRegular, faClockRegular as IconDefinition);
  library.add(faEnvelopeRegular, faEnvelopeRegular as IconDefinition);

}

export default initFontAwesome;
